(function (Translator) {
    // pt
    Translator.add("title_user_registration", "title_user_registration", "SonataUserBundle", "pt");
    Translator.add("title_user_account", "Perifl de Utilizador", "SonataUserBundle", "pt");
    Translator.add("title_user_edit_profile", "Editar", "SonataUserBundle", "pt");
    Translator.add("title_user_authentication", "Authentication", "SonataUserBundle", "pt");
    Translator.add("sonata_user_submit", "Enviar", "SonataUserBundle", "pt");
    Translator.add("breadcrumb.link_user_list", "Utilizadores", "SonataUserBundle", "pt");
    Translator.add("breadcrumb.link_user_create", "Criar", "SonataUserBundle", "pt");
    Translator.add("breadcrumb.link_user_edit", "Editar", "SonataUserBundle", "pt");
    Translator.add("breadcrumb.link_user_delete", "Eliminar", "SonataUserBundle", "pt");
    Translator.add("breadcrumb.link_group_list", "Grupos", "SonataUserBundle", "pt");
    Translator.add("breadcrumb.link_group_create", "Criar", "SonataUserBundle", "pt");
    Translator.add("breadcrumb.link_group_edit", "Editar", "SonataUserBundle", "pt");
    Translator.add("users", "Utilizadores", "SonataUserBundle", "pt");
    Translator.add("groups", "Grupos", "SonataUserBundle", "pt");
    Translator.add("switch_user_exit", "Sair do modo de personifica\u00e7\u00e3o", "SonataUserBundle", "pt");
    Translator.add("switch_user", "Personificar utilizador", "SonataUserBundle", "pt");
    Translator.add("user_block_logout", "Terminar sess\u00e3o", "SonataUserBundle", "pt");
    Translator.add("user_block_profile", "user_block_profile", "SonataUserBundle", "pt");
    Translator.add("form.label_username", "Nome de utilizador", "SonataUserBundle", "pt");
    Translator.add("form.label_email", "Endere\u00e7o de email", "SonataUserBundle", "pt");
    Translator.add("form.label_plain_password", "Palavra-passe", "SonataUserBundle", "pt");
    Translator.add("form.label_groups", "Grupos", "SonataUserBundle", "pt");
    Translator.add("form.label_roles", "Perf\u00eds", "SonataUserBundle", "pt");
    Translator.add("form.label_locked", "Bloqueado", "SonataUserBundle", "pt");
    Translator.add("form.label_expired", "Expirado", "SonataUserBundle", "pt");
    Translator.add("form.label_enabled", "Habilitado", "SonataUserBundle", "pt");
    Translator.add("form.label_credentials_expired", "Credenciais expiradas", "SonataUserBundle", "pt");
    Translator.add("form.label_name", "Nome", "SonataUserBundle", "pt");
    Translator.add("filter.label_username", "Nome de utilizador", "SonataUserBundle", "pt");
    Translator.add("filter.label_name", "Nome", "SonataUserBundle", "pt");
    Translator.add("filter.label_email", "Endere\u00e7o de email", "SonataUserBundle", "pt");
    Translator.add("filter.label_locked", "Bloqueado", "SonataUserBundle", "pt");
    Translator.add("filter.label_id", "ID", "SonataUserBundle", "pt");
    Translator.add("list.label_username", "Nome de utilizador", "SonataUserBundle", "pt");
    Translator.add("list.label_name", "Nome", "SonataUserBundle", "pt");
    Translator.add("list.label_email", "Endere\u00e7o de email", "SonataUserBundle", "pt");
    Translator.add("list.label_locked", "Bloqueado", "SonataUserBundle", "pt");
    Translator.add("list.label_enabled", "Habilitado", "SonataUserBundle", "pt");
    Translator.add("list.label_created_at", "Criado em", "SonataUserBundle", "pt");
    Translator.add("list.label_impersonating", "Personificar utilizador", "SonataUserBundle", "pt");
    Translator.add("list.label_roles", "Perf\u00eds", "SonataUserBundle", "pt");
    Translator.add("label_two_step_code", "label_two_step_code", "SonataUserBundle", "pt");
    Translator.add("message_two_step_code_help", "message_two_step_code_help", "SonataUserBundle", "pt");
    Translator.add("label_two_step_code_error", "label_two_step_code_error", "SonataUserBundle", "pt");
    Translator.add("sonata_user", "Users", "SonataUserBundle", "pt");
    Translator.add("form.label_website", "form.label_website", "SonataUserBundle", "pt");
    Translator.add("form.label_biography", "form.label_biography", "SonataUserBundle", "pt");
    Translator.add("form.label_gender", "form.label_gender", "SonataUserBundle", "pt");
    Translator.add("form.label_locale", "form.label_locale", "SonataUserBundle", "pt");
    Translator.add("form.label_timezone", "form.label_timezone", "SonataUserBundle", "pt");
    Translator.add("form.label_phone", "form.label_phone", "SonataUserBundle", "pt");
    Translator.add("form.label_facebook_uid", "form.label_facebook_uid", "SonataUserBundle", "pt");
    Translator.add("form.label_facebook_name", "form.label_facebook_name", "SonataUserBundle", "pt");
    Translator.add("form.label_twitter_uid", "form.label_twitter_uid", "SonataUserBundle", "pt");
    Translator.add("form.label_twitter_name", "form.label_twitter_name", "SonataUserBundle", "pt");
    Translator.add("form.label_gplus_uid", "form.label_gplus_uid", "SonataUserBundle", "pt");
    Translator.add("form.label_gplus_name", "form.label_gplus_name", "SonataUserBundle", "pt");
    Translator.add("form.label_token", "form.label_token", "SonataUserBundle", "pt");
    Translator.add("form.label_two_step_verification_code", "form.label_two_step_verification_code", "SonataUserBundle", "pt");
    Translator.add("show.label_username", "show.label_username", "SonataUserBundle", "pt");
    Translator.add("show.label_email", "show.label_email", "SonataUserBundle", "pt");
    Translator.add("show.label_groups", "show.label_groups", "SonataUserBundle", "pt");
    Translator.add("show.label_date_of_birth", "show.label_date_of_birth", "SonataUserBundle", "pt");
    Translator.add("show.label_firstname", "show.label_firstname", "SonataUserBundle", "pt");
    Translator.add("show.label_lastname", "show.label_lastname", "SonataUserBundle", "pt");
    Translator.add("show.label_website", "show.label_website", "SonataUserBundle", "pt");
    Translator.add("show.label_biography", "show.label_biography", "SonataUserBundle", "pt");
    Translator.add("show.label_gender", "show.label_gender", "SonataUserBundle", "pt");
    Translator.add("show.label_locale", "show.label_locale", "SonataUserBundle", "pt");
    Translator.add("show.label_timezone", "show.label_timezone", "SonataUserBundle", "pt");
    Translator.add("show.label_phone", "show.label_phone", "SonataUserBundle", "pt");
    Translator.add("show.label_facebook_uid", "show.label_facebook_uid", "SonataUserBundle", "pt");
    Translator.add("show.label_facebook_name", "show.label_facebook_name", "SonataUserBundle", "pt");
    Translator.add("show.label_twitter_uid", "show.label_twitter_uid", "SonataUserBundle", "pt");
    Translator.add("show.label_twitter_name", "show.label_twitter_name", "SonataUserBundle", "pt");
    Translator.add("show.label_gplus_uid", "show.label_gplus_uid", "SonataUserBundle", "pt");
    Translator.add("show.label_gplus_name", "show.label_gplus_name", "SonataUserBundle", "pt");
    Translator.add("show.label_token", "show.label_token", "SonataUserBundle", "pt");
    Translator.add("show.label_two_step_verification_code", "show.label_two_step_verification_code", "SonataUserBundle", "pt");
    Translator.add("breadcrumb.link_user_show", "breadcrumb.link_user_show", "SonataUserBundle", "pt");
    Translator.add("form.label_date_of_birth", "Date of birth", "SonataUserBundle", "pt");
    Translator.add("gender_unknown", "gender_unknown", "SonataUserBundle", "pt");
    Translator.add("gender_female", "gender_female", "SonataUserBundle", "pt");
    Translator.add("gender_male", "gender_male", "SonataUserBundle", "pt");
    Translator.add("sonata_profile_title", "sonata_profile_title", "SonataUserBundle", "pt");
    Translator.add("link_show_profile", "link_show_profile", "SonataUserBundle", "pt");
    Translator.add("link_edit_profile", "link_edit_profile", "SonataUserBundle", "pt");
    Translator.add("title_user_edit_authentication", "title_user_edit_authentication", "SonataUserBundle", "pt");
    Translator.add("link_edit_authentication", "link_edit_authentication", "SonataUserBundle", "pt");
    Translator.add("label_profile_gender", "label_profile_gender", "SonataUserBundle", "pt");
    Translator.add("label_profile_firstname", "label_profile_firstname", "SonataUserBundle", "pt");
    Translator.add("label_profile_lastname", "label_profile_lastname", "SonataUserBundle", "pt");
    Translator.add("label_profile_website", "label_profile_website", "SonataUserBundle", "pt");
    Translator.add("label_profile_dob", "label_profile_dob", "SonataUserBundle", "pt");
    Translator.add("label_profile_biography", "label_profile_biography", "SonataUserBundle", "pt");
    Translator.add("label_profile_locale", "label_profile_locale", "SonataUserBundle", "pt");
    Translator.add("label_profile_timezone", "label_profile_timezone", "SonataUserBundle", "pt");
    Translator.add("label_profile_phone", "label_profile_phone", "SonataUserBundle", "pt");
    Translator.add("profile.flash.updated", "profile.flash.updated", "SonataUserBundle", "pt");
    Translator.add("sonata_change_password_link", "sonata_change_password_link", "SonataUserBundle", "pt");
    Translator.add("link_register", "link_register", "SonataUserBundle", "pt");
    Translator.add("link_login", "link_login", "SonataUserBundle", "pt");
    Translator.add("link_logout", "link_logout", "SonataUserBundle", "pt");
    Translator.add("registration.flash.user_created", "registration.flash.user_created", "SonataUserBundle", "pt");
    Translator.add("forgotten_password", "forgotten_password", "SonataUserBundle", "pt");
    Translator.add("sonata_user_profile_breadcrumb_index", "sonata_user_profile_breadcrumb_index", "SonataUserBundle", "pt");
    Translator.add("sonata_user_profile_breadcrumb_edit", "sonata_user_profile_breadcrumb_edit", "SonataUserBundle", "pt");
    Translator.add("sonata_user_already_authenticated", "You are already logged in", "SonataUserBundle", "pt");
    Translator.add("security.login.username", "security.login.username", "SonataUserBundle", "pt");
    Translator.add("security.login.password", "security.login.password", "SonataUserBundle", "pt");
    Translator.add("form.username", "form.username", "SonataUserBundle", "pt");
    Translator.add("form.email", "form.email", "SonataUserBundle", "pt");
    Translator.add("form.password", "form.password", "SonataUserBundle", "pt");
    Translator.add("form.password_confirmation", "form.password_erification", "SonataUserBundle", "pt");
})(Translator);
