(function (Translator) {
    // en
    Translator.add("link_add", "Add new", "SonataCoreBundle", "en");
    Translator.add("label_type_yes", "yes", "SonataCoreBundle", "en");
    Translator.add("label_type_no", "no", "SonataCoreBundle", "en");
    Translator.add("sonata_core_template_box_file_found_in", "This file can be found in", "SonataCoreBundle", "en");
    Translator.add("label_type_equals", "is equal to", "SonataCoreBundle", "en");
    Translator.add("label_type_not_equals", "is not equal to", "SonataCoreBundle", "en");
    Translator.add("date_range_start", "From", "SonataCoreBundle", "en");
    Translator.add("date_range_end", "To", "SonataCoreBundle", "en");
    Translator.add("aliceblue", "AliceBlue", "SonataCoreBundle", "en");
    Translator.add("antiquewhite", "AntiqueWhite", "SonataCoreBundle", "en");
    Translator.add("aqua", "Aqua", "SonataCoreBundle", "en");
    Translator.add("aquamarine", "Aquamarine", "SonataCoreBundle", "en");
    Translator.add("azure", "Azure", "SonataCoreBundle", "en");
    Translator.add("beige", "Beige", "SonataCoreBundle", "en");
    Translator.add("bisque", "Bisque", "SonataCoreBundle", "en");
    Translator.add("black", "Black", "SonataCoreBundle", "en");
    Translator.add("blanchedalmond", "BlanchedAlmond", "SonataCoreBundle", "en");
    Translator.add("blue", "Blue", "SonataCoreBundle", "en");
    Translator.add("blueviolet", "BlueViolet", "SonataCoreBundle", "en");
    Translator.add("brown", "Brown", "SonataCoreBundle", "en");
    Translator.add("burlywood", "BurlyWood", "SonataCoreBundle", "en");
    Translator.add("cadetblue", "CadetBlue", "SonataCoreBundle", "en");
    Translator.add("chartreuse", "Chartreuse", "SonataCoreBundle", "en");
    Translator.add("chocolate", "Chocolate", "SonataCoreBundle", "en");
    Translator.add("coral", "Coral", "SonataCoreBundle", "en");
    Translator.add("cornflowerblue", "CornflowerBlue", "SonataCoreBundle", "en");
    Translator.add("cornsilk", "Cornsilk", "SonataCoreBundle", "en");
    Translator.add("crimson", "Crimson", "SonataCoreBundle", "en");
    Translator.add("cyan", "Cyan", "SonataCoreBundle", "en");
    Translator.add("darkblue", "DarkBlue", "SonataCoreBundle", "en");
    Translator.add("darkcyan", "DarkCyan", "SonataCoreBundle", "en");
    Translator.add("darkgoldenrod", "DarkGoldenRod", "SonataCoreBundle", "en");
    Translator.add("darkgray", "DarkGray", "SonataCoreBundle", "en");
    Translator.add("darkgreen", "DarkGreen", "SonataCoreBundle", "en");
    Translator.add("darkkhaki", "DarkKhaki", "SonataCoreBundle", "en");
    Translator.add("darkmagenta", "DarkMagenta", "SonataCoreBundle", "en");
    Translator.add("darkolivegreen", "DarkOliveGreen", "SonataCoreBundle", "en");
    Translator.add("darkorange", "DarkOrange", "SonataCoreBundle", "en");
    Translator.add("darkorchid", "DarkOrchid", "SonataCoreBundle", "en");
    Translator.add("darkred", "DarkRed", "SonataCoreBundle", "en");
    Translator.add("darksalmon", "DarkSalmon", "SonataCoreBundle", "en");
    Translator.add("darkseagreen", "DarkSeaGreen", "SonataCoreBundle", "en");
    Translator.add("darkslateblue", "DarkSlateBlue", "SonataCoreBundle", "en");
    Translator.add("darkslategray", "DarkSlateGray", "SonataCoreBundle", "en");
    Translator.add("darkturquoise", "DarkTurquoise", "SonataCoreBundle", "en");
    Translator.add("darkviolet", "DarkViolet", "SonataCoreBundle", "en");
    Translator.add("deeppink", "DeepPink", "SonataCoreBundle", "en");
    Translator.add("deepskyblue", "DeepSkyBlue", "SonataCoreBundle", "en");
    Translator.add("dimgray", "DimGray", "SonataCoreBundle", "en");
    Translator.add("dodgerblue", "DodgerBlue", "SonataCoreBundle", "en");
    Translator.add("firebrick", "FireBrick", "SonataCoreBundle", "en");
    Translator.add("floralwhite", "FloralWhite", "SonataCoreBundle", "en");
    Translator.add("forestgreen", "ForestGreen", "SonataCoreBundle", "en");
    Translator.add("fuchsia", "Fuchsia", "SonataCoreBundle", "en");
    Translator.add("gainsboro", "Gainsboro", "SonataCoreBundle", "en");
    Translator.add("ghostwhite", "GhostWhite", "SonataCoreBundle", "en");
    Translator.add("gold", "Gold", "SonataCoreBundle", "en");
    Translator.add("goldenrod", "GoldenRod", "SonataCoreBundle", "en");
    Translator.add("gray", "Gray", "SonataCoreBundle", "en");
    Translator.add("green", "Green", "SonataCoreBundle", "en");
    Translator.add("greenyellow", "GreenYellow", "SonataCoreBundle", "en");
    Translator.add("honeydew", "HoneyDew", "SonataCoreBundle", "en");
    Translator.add("hotpink", "HotPink", "SonataCoreBundle", "en");
    Translator.add("indianred", "IndianRed", "SonataCoreBundle", "en");
    Translator.add("indigo", "Indigo", "SonataCoreBundle", "en");
    Translator.add("ivory", "Ivory", "SonataCoreBundle", "en");
    Translator.add("khaki", "Khaki", "SonataCoreBundle", "en");
    Translator.add("lavender", "Lavender", "SonataCoreBundle", "en");
    Translator.add("lavenderblush", "LavenderBlush", "SonataCoreBundle", "en");
    Translator.add("lawngreen", "LawnGreen", "SonataCoreBundle", "en");
    Translator.add("lemonchiffon", "LemonChiffon", "SonataCoreBundle", "en");
    Translator.add("lightblue", "LightBlue", "SonataCoreBundle", "en");
    Translator.add("lightcoral", "LightCoral", "SonataCoreBundle", "en");
    Translator.add("lightcyan", "LightCyan", "SonataCoreBundle", "en");
    Translator.add("lightgoldenrodyellow", "LightGoldenRodYellow", "SonataCoreBundle", "en");
    Translator.add("lightgray", "LightGray", "SonataCoreBundle", "en");
    Translator.add("lightgreen", "LightGreen", "SonataCoreBundle", "en");
    Translator.add("lightpink", "LightPink", "SonataCoreBundle", "en");
    Translator.add("lightsalmon", "LightSalmon", "SonataCoreBundle", "en");
    Translator.add("lightseagreen", "LightSeaGreen", "SonataCoreBundle", "en");
    Translator.add("lightskyblue", "LightSkyBlue", "SonataCoreBundle", "en");
    Translator.add("lightslategray", "LightSlateGray", "SonataCoreBundle", "en");
    Translator.add("lightsteelblue", "LightSteelBlue", "SonataCoreBundle", "en");
    Translator.add("lightyellow", "LightYellow", "SonataCoreBundle", "en");
    Translator.add("lime", "Lime", "SonataCoreBundle", "en");
    Translator.add("limegreen", "LimeGreen", "SonataCoreBundle", "en");
    Translator.add("linen", "Linen", "SonataCoreBundle", "en");
    Translator.add("magenta", "Magenta", "SonataCoreBundle", "en");
    Translator.add("maroon", "Maroon", "SonataCoreBundle", "en");
    Translator.add("mediumaquamarine", "MediumAquaMarine", "SonataCoreBundle", "en");
    Translator.add("mediumblue", "MediumBlue", "SonataCoreBundle", "en");
    Translator.add("mediumorchid", "MediumOrchid", "SonataCoreBundle", "en");
    Translator.add("mediumpurple", "MediumPurple", "SonataCoreBundle", "en");
    Translator.add("mediumseagreen", "MediumSeaGreen", "SonataCoreBundle", "en");
    Translator.add("mediumslateblue", "MediumSlateBlue", "SonataCoreBundle", "en");
    Translator.add("mediumspringgreen", "MediumSpringGreen", "SonataCoreBundle", "en");
    Translator.add("mediumturquoise", "MediumTurquoise", "SonataCoreBundle", "en");
    Translator.add("mediumvioletred", "MediumVioletRed", "SonataCoreBundle", "en");
    Translator.add("midnightblue", "MidnightBlue", "SonataCoreBundle", "en");
    Translator.add("mintcream", "MintCream", "SonataCoreBundle", "en");
    Translator.add("mistyrose", "MistyRose", "SonataCoreBundle", "en");
    Translator.add("moccasin", "Moccasin", "SonataCoreBundle", "en");
    Translator.add("navajowhite", "NavajoWhite", "SonataCoreBundle", "en");
    Translator.add("navy", "Navy", "SonataCoreBundle", "en");
    Translator.add("oldlace", "OldLace", "SonataCoreBundle", "en");
    Translator.add("olive", "Olive", "SonataCoreBundle", "en");
    Translator.add("olivedrab", "OliveDrab", "SonataCoreBundle", "en");
    Translator.add("orange", "Orange", "SonataCoreBundle", "en");
    Translator.add("orangered", "OrangeRed", "SonataCoreBundle", "en");
    Translator.add("orchid", "Orchid", "SonataCoreBundle", "en");
    Translator.add("palegoldenrod", "PaleGoldenRod", "SonataCoreBundle", "en");
    Translator.add("palegreen", "PaleGreen", "SonataCoreBundle", "en");
    Translator.add("paleturquoise", "PaleTurquoise", "SonataCoreBundle", "en");
    Translator.add("palevioletred", "PaleVioletRed", "SonataCoreBundle", "en");
    Translator.add("papayawhip", "PapayaWhip", "SonataCoreBundle", "en");
    Translator.add("peachpuff", "PeachPuff", "SonataCoreBundle", "en");
    Translator.add("peru", "Peru", "SonataCoreBundle", "en");
    Translator.add("pink", "Pink", "SonataCoreBundle", "en");
    Translator.add("plum", "Plum", "SonataCoreBundle", "en");
    Translator.add("powderblue", "PowderBlue", "SonataCoreBundle", "en");
    Translator.add("purple", "Purple", "SonataCoreBundle", "en");
    Translator.add("rebeccapurple", "RebeccaPurple", "SonataCoreBundle", "en");
    Translator.add("red", "Red", "SonataCoreBundle", "en");
    Translator.add("rosybrown", "RosyBrown", "SonataCoreBundle", "en");
    Translator.add("royalblue", "RoyalBlue", "SonataCoreBundle", "en");
    Translator.add("saddlebrown", "SaddleBrown", "SonataCoreBundle", "en");
    Translator.add("salmon", "Salmon", "SonataCoreBundle", "en");
    Translator.add("sandybrown", "SandyBrown", "SonataCoreBundle", "en");
    Translator.add("seagreen", "SeaGreen", "SonataCoreBundle", "en");
    Translator.add("seashell", "SeaShell", "SonataCoreBundle", "en");
    Translator.add("sienna", "Sienna", "SonataCoreBundle", "en");
    Translator.add("silver", "Silver", "SonataCoreBundle", "en");
    Translator.add("skyblue", "SkyBlue", "SonataCoreBundle", "en");
    Translator.add("slateblue", "SlateBlue", "SonataCoreBundle", "en");
    Translator.add("slategray", "SlateGray", "SonataCoreBundle", "en");
    Translator.add("snow", "Snow", "SonataCoreBundle", "en");
    Translator.add("springgreen", "SpringGreen", "SonataCoreBundle", "en");
    Translator.add("steelblue", "SteelBlue", "SonataCoreBundle", "en");
    Translator.add("tan", "Tan", "SonataCoreBundle", "en");
    Translator.add("teal", "Teal", "SonataCoreBundle", "en");
    Translator.add("thistle", "Thistle", "SonataCoreBundle", "en");
    Translator.add("tomato", "Tomato", "SonataCoreBundle", "en");
    Translator.add("turquoise", "Turquoise", "SonataCoreBundle", "en");
    Translator.add("violet", "Violet", "SonataCoreBundle", "en");
    Translator.add("wheat", "Wheat", "SonataCoreBundle", "en");
    Translator.add("white", "White", "SonataCoreBundle", "en");
    Translator.add("whitesmoke", "WhiteSmoke", "SonataCoreBundle", "en");
    Translator.add("yellow", "Yellow", "SonataCoreBundle", "en");
    Translator.add("yellowgreen", "YellowGreen", "SonataCoreBundle", "en");
})(Translator);
