(function (Translator) {
    // pt
    Translator.add("logo.inline", "logo\/logo_inline.png", "images", "pt");
    Translator.add("logo", "logo\/logo.png", "images", "pt");
    Translator.add("logo.alt", "logo de A Cascata", "images", "pt");
    Translator.add("blog.main_photo.alt", "imagem principal do blog", "images", "pt");
    Translator.add("blog.post.picture.alt", "imagem da publica\u00e7\u00e3o do blog", "images", "pt");
    Translator.add("homepage.main_photo.alt", "Bife \u00e0 cascata", "images", "pt");
    Translator.add("homepage.drinks.alt", "Conjunto de bebidas em A Cascata", "images", "pt");
    Translator.add("homepage.main_photo.big", "homepage\/main_photo.jpg", "images", "pt");
})(Translator);
