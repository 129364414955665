(function (Translator) {
    // pt
    Translator.add("This value should be false.", "Este valor deveria ser falso.", "validators", "pt");
    Translator.add("This value should be true.", "Este valor deveria ser verdadeiro.", "validators", "pt");
    Translator.add("This value should be of type {{ type }}.", "Este valor deveria ser do tipo {{ type }}.", "validators", "pt");
    Translator.add("This value should be blank.", "Este valor deveria ser vazio.", "validators", "pt");
    Translator.add("The value you selected is not a valid choice.", "O valor selecionado n\u00e3o \u00e9 uma op\u00e7\u00e3o v\u00e1lida.", "validators", "pt");
    Translator.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "Voc\u00ea deveria selecionar {{ limit }} op\u00e7\u00e3o no m\u00ednimo.|Voc\u00ea deveria selecionar {{ limit }} op\u00e7\u00f5es no m\u00ednimo.", "validators", "pt");
    Translator.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "Voc\u00ea deve selecionar, no m\u00e1ximo {{ limit }} op\u00e7\u00e3o.|Voc\u00ea deve selecionar, no m\u00e1ximo {{ limit }} op\u00e7\u00f5es.", "validators", "pt");
    Translator.add("One or more of the given values is invalid.", "Um ou mais dos valores introduzidos n\u00e3o s\u00e3o v\u00e1lidos.", "validators", "pt");
    Translator.add("This field was not expected.", "Este campo n\u00e3o era esperado.", "validators", "pt");
    Translator.add("This field is missing.", "Este campo est\u00e1 faltando.", "validators", "pt");
    Translator.add("This value is not a valid date.", "Este valor n\u00e3o \u00e9 uma data v\u00e1lida.", "validators", "pt");
    Translator.add("This value is not a valid datetime.", "Este valor n\u00e3o \u00e9 uma data-hora v\u00e1lida.", "validators", "pt");
    Translator.add("This value is not a valid email address.", "Este valor n\u00e3o \u00e9 um endere\u00e7o de e-mail v\u00e1lido.", "validators", "pt");
    Translator.add("The file could not be found.", "O arquivo n\u00e3o p\u00f4de ser encontrado.", "validators", "pt");
    Translator.add("The file is not readable.", "O arquivo n\u00e3o p\u00f4de ser lido.", "validators", "pt");
    Translator.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "O arquivo \u00e9 muito grande ({{ size }} {{ suffix }}). O tamanho m\u00e1ximo permitido \u00e9 de {{ limit }} {{ suffix }}.", "validators", "pt");
    Translator.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "O tipo mime do arquivo \u00e9 inv\u00e1lido ({{ type }}). Os tipos mime permitidos s\u00e3o {{ types }}.", "validators", "pt");
    Translator.add("This value should be {{ limit }} or less.", "Este valor deveria ser {{ limit }} ou menor.", "validators", "pt");
    Translator.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "O valor \u00e9 muito longo. Deveria ter {{ limit }} caracteres ou menos.", "validators", "pt");
    Translator.add("This value should be {{ limit }} or more.", "Este valor deveria ser {{ limit }} ou mais.", "validators", "pt");
    Translator.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "O valor \u00e9 muito curto. Deveria de ter {{ limit }} caractere ou mais.|O valor \u00e9 muito curto. Deveria de ter {{ limit }} caracteres ou mais.", "validators", "pt");
    Translator.add("This value should not be blank.", "Este valor n\u00e3o deveria ser branco\/vazio.", "validators", "pt");
    Translator.add("This value should not be null.", "Este valor n\u00e3o deveria ser nulo.", "validators", "pt");
    Translator.add("This value should be null.", "Este valor deveria ser nulo.", "validators", "pt");
    Translator.add("This value is not valid.", "Este valor n\u00e3o \u00e9 v\u00e1lido.", "validators", "pt");
    Translator.add("This value is not a valid time.", "Este valor n\u00e3o \u00e9 uma hora v\u00e1lida.", "validators", "pt");
    Translator.add("This value is not a valid URL.", "Este valor n\u00e3o \u00e9 um URL v\u00e1lido.", "validators", "pt");
    Translator.add("The two values should be equal.", "Os dois valores deveriam ser iguais.", "validators", "pt");
    Translator.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "O arquivo \u00e9 muito grande. O tamanho m\u00e1ximo permitido \u00e9 de {{ limit }} {{ suffix }}.", "validators", "pt");
    Translator.add("The file is too large.", "O ficheiro \u00e9 muito grande.", "validators", "pt");
    Translator.add("The file could not be uploaded.", "N\u00e3o foi poss\u00edvel carregar o ficheiro.", "validators", "pt");
    Translator.add("This value should be a valid number.", "Este valor deveria de ser um n\u00famero v\u00e1lido.", "validators", "pt");
    Translator.add("This file is not a valid image.", "Este ficheiro n\u00e3o \u00e9 uma imagem.", "validators", "pt");
    Translator.add("This is not a valid IP address.", "Este endere\u00e7o de IP n\u00e3o \u00e9 v\u00e1lido.", "validators", "pt");
    Translator.add("This value is not a valid language.", "Este valor n\u00e3o \u00e9 uma linguagem v\u00e1lida.", "validators", "pt");
    Translator.add("This value is not a valid locale.", "Este valor n\u00e3o \u00e9 um 'locale' v\u00e1lido.", "validators", "pt");
    Translator.add("This value is not a valid country.", "Este valor n\u00e3o \u00e9 um Pa\u00eds v\u00e1lido.", "validators", "pt");
    Translator.add("This value is already used.", "Este valor j\u00e1 est\u00e1 a ser usado.", "validators", "pt");
    Translator.add("The size of the image could not be detected.", "O tamanho da imagem n\u00e3o foi detetado.", "validators", "pt");
    Translator.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "A largura da imagem ({{ width }}px) \u00e9 muito grande. A largura m\u00e1xima da imagem \u00e9: {{ max_width }}px.", "validators", "pt");
    Translator.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "A largura da imagem ({{ width }}px) \u00e9 muito pequena. A largura min\u00edma da imagem \u00e9 de: {{ min_width }}px.", "validators", "pt");
    Translator.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "A altura da imagem ({{ height }}px) \u00e9 muito grande. A altura m\u00e1xima da imagem \u00e9 de: {{ max_height }}px.", "validators", "pt");
    Translator.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "A altura da imagem ({{ height }}px) \u00e9 muito pequena. A altura min\u00edma da imagem \u00e9 de: {{ min_height }}px.", "validators", "pt");
    Translator.add("This value should be the user's current password.", "Este valor deveria de ser a password atual do utilizador.", "validators", "pt");
    Translator.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "Este valor tem de ter exatamente {{ limit }} carateres.", "validators", "pt");
    Translator.add("The file was only partially uploaded.", "S\u00f3 foi enviado parte do ficheiro.", "validators", "pt");
    Translator.add("No file was uploaded.", "Nenhum ficheiro foi enviado.", "validators", "pt");
    Translator.add("No temporary folder was configured in php.ini.", "N\u00e3o existe nenhum direct\u00f3rio tempor\u00e1ria configurado no ficheiro php.ini.", "validators", "pt");
    Translator.add("Cannot write temporary file to disk.", "N\u00e3o foi poss\u00edvel escrever ficheiros tempor\u00e1rios no disco.", "validators", "pt");
    Translator.add("A PHP extension caused the upload to fail.", "Uma extens\u00e3o PHP causou a falha no envio.", "validators", "pt");
    Translator.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "Esta cole\u00e7\u00e3o deve conter {{ limit }} elemento ou mais.|Esta cole\u00e7\u00e3o deve conter {{ limit }} elementos ou mais.", "validators", "pt");
    Translator.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "Esta cole\u00e7\u00e3o deve conter {{ limit }} elemento ou menos.|Esta cole\u00e7\u00e3o deve conter {{ limit }} elementos ou menos.", "validators", "pt");
    Translator.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "Esta cole\u00e7\u00e3o deve conter exatamente {{ limit }} elemento.|Esta cole\u00e7\u00e3o deve conter exatamente {{ limit }} elementos.", "validators", "pt");
    Translator.add("Invalid card number.", "N\u00famero de cart\u00e3o inv\u00e1lido.", "validators", "pt");
    Translator.add("Unsupported card type or invalid card number.", "Tipo de cart\u00e3o n\u00e3o suportado ou n\u00famero de cart\u00e3o inv\u00e1lido.", "validators", "pt");
    Translator.add("This is not a valid International Bank Account Number (IBAN).", "Este n\u00e3o \u00e9 um N\u00famero Internacional de Conta Banc\u00e1ria (IBAN) v\u00e1lido.", "validators", "pt");
    Translator.add("This value is not a valid ISBN-10.", "Este valor n\u00e3o \u00e9 um ISBN-10 v\u00e1lido.", "validators", "pt");
    Translator.add("This value is not a valid ISBN-13.", "Este valor n\u00e3o \u00e9 um ISBN-13 v\u00e1lido.", "validators", "pt");
    Translator.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "Este valor n\u00e3o \u00e9 um ISBN-10 ou ISBN-13 v\u00e1lido.", "validators", "pt");
    Translator.add("This value is not a valid ISSN.", "Este valor n\u00e3o \u00e9 um ISSN v\u00e1lido.", "validators", "pt");
    Translator.add("This value is not a valid currency.", "Este n\u00e3o \u00e9 um valor monet\u00e1rio v\u00e1lido.", "validators", "pt");
    Translator.add("This value should be equal to {{ compared_value }}.", "Este valor deve ser igual a {{ compared_value }}.", "validators", "pt");
    Translator.add("This value should be greater than {{ compared_value }}.", "Este valor deve ser superior a {{ compared_value }}.", "validators", "pt");
    Translator.add("This value should be greater than or equal to {{ compared_value }}.", "Este valor deve ser igual ou superior a {{ compared_value }}.", "validators", "pt");
    Translator.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "Este valor deve ser id\u00eantico a {{ compared_value_type }} {{ compared_value }}.", "validators", "pt");
    Translator.add("This value should be less than {{ compared_value }}.", "Este valor deve ser inferior a {{ compared_value }}.", "validators", "pt");
    Translator.add("This value should be less than or equal to {{ compared_value }}.", "Este valor deve ser igual ou inferior a {{ compared_value }}.", "validators", "pt");
    Translator.add("This value should not be equal to {{ compared_value }}.", "Este valor n\u00e3o deve ser igual a {{ compared_value }}.", "validators", "pt");
    Translator.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "Este valor n\u00e3o deve ser id\u00eantico a {{ compared_value_type }} {{ compared_value }}.", "validators", "pt");
    Translator.add("The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}.", "O formato da imagem \u00e9 muito grande ({{ ratio }}). O formato m\u00e1ximo \u00e9 {{ max_ratio }}.", "validators", "pt");
    Translator.add("The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}.", "O formato da imagem \u00e9 muito pequeno ({{ ratio }}). O formato m\u00ednimo esperado \u00e9 {{ min_ratio }}.", "validators", "pt");
    Translator.add("The image is square ({{ width }}x{{ height }}px). Square images are not allowed.", "A imagem \u00e9 um quadrado ({{ width }}x{{ height }}px). Imagens quadradas n\u00e3o s\u00e3o permitidas.", "validators", "pt");
    Translator.add("The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed.", "A imagem est\u00e1 orientada \u00e0 paisagem ({{ width }}x{{ height }}px). Imagens orientadas \u00e0 paisagem n\u00e3o s\u00e3o permitidas.", "validators", "pt");
    Translator.add("The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed.", "A imagem est\u00e1 orientada ao retrato ({{ width }}x{{ height }}px). Imagens orientadas ao retrato n\u00e3o s\u00e3o permitidas.", "validators", "pt");
    Translator.add("An empty file is not allowed.", "Ficheiro vazio n\u00e3o \u00e9 permitido.", "validators", "pt");
    Translator.add("This form should not contain extra fields.", "Este formul\u00e1rio n\u00e3o deveria conter campos extra.", "validators", "pt");
    Translator.add("The uploaded file was too large. Please try to upload a smaller file.", "O arquivo enviado \u00e9 muito grande. Por favor, tente enviar um ficheiro mais pequeno.", "validators", "pt");
    Translator.add("The CSRF token is invalid. Please try to resubmit the form.", "O token CSRF \u00e9 inv\u00e1lido. Por favor submeta o formul\u00e1rio novamente.", "validators", "pt");
    Translator.add("fos_user.username.already_used", "Este utilizador j\u00e1 existe", "validators", "pt");
    Translator.add("fos_user.username.blank", "Por favor introduza o nome de utilizador", "validators", "pt");
    Translator.add("fos_user.username.short", "[-Inf,Inf]O nome de utilizador \u00e9 muito curto", "validators", "pt");
    Translator.add("fos_user.username.long", "[-Inf,Inf]O nome de utilizador \u00e9 muito longo", "validators", "pt");
    Translator.add("fos_user.email.already_used", "Este email j\u00e1 est\u00e1 a ser usado", "validators", "pt");
    Translator.add("fos_user.email.blank", "Por favor introduza o email", "validators", "pt");
    Translator.add("fos_user.email.short", "[-Inf,Inf]Este email \u00e9 muito curto", "validators", "pt");
    Translator.add("fos_user.email.long", "[-Inf,Inf]Este email \u00e9 muito longo", "validators", "pt");
    Translator.add("fos_user.email.invalid", "Este email \u00e9 inv\u00e1lido", "validators", "pt");
    Translator.add("fos_user.password.blank", "Por favor introduza a senha", "validators", "pt");
    Translator.add("fos_user.password.short", "[-Inf,Inf]Esta senha \u00e9 muito curta", "validators", "pt");
    Translator.add("fos_user.password.mismatch", "As senhas n\u00e3o correspondem", "validators", "pt");
    Translator.add("fos_user.new_password.blank", "Por favor introduza a nova senha", "validators", "pt");
    Translator.add("fos_user.new_password.short", "[-Inf,Inf]A nova senha \u00e9 muito curta", "validators", "pt");
    Translator.add("fos_user.current_password.invalid", "A senha est\u00e1 incorreta", "validators", "pt");
    Translator.add("fos_user.group.blank", "Por favor introduza o nome", "validators", "pt");
    Translator.add("fos_user.group.short", "[-Inf,Inf]O nome \u00e9 muito curto", "validators", "pt");
    Translator.add("fos_user.group.long", "[-Inf,Inf]O nome \u00e9 muito longo", "validators", "pt");
    Translator.add("data.type.invalid.correct_integer", "Insira apenas valores num\u00e9ricos inteiros.", "validators", "pt");
    Translator.add("data.type.invalid.correct_numeric", "Insira apenas valores num\u00e9ricos.", "validators", "pt");
    Translator.add("date.invalid", "Data de nascimento \u00e9 inv\u00e1lida", "validators", "pt");
    Translator.add("email.invalid", "Email inv\u00e1lido.", "validators", "pt");
    Translator.add("form.field.greater_than", "O texto deve ter mais de {{ limit }} caracteres.", "validators", "pt");
    Translator.add("latitude_longitude", "Valores de Latitude e\/ou Longitude inv\u00e1lidos (\"%lat%\" e \"%lng%\").", "validators", "pt");
    Translator.add("mandatory.field", "Campo obrigat\u00f3rio.", "validators", "pt");
    Translator.add("product.name.length", "O nome deve ter entre 10 e 255 caracteres.", "validators", "pt");
    Translator.add("product.select.list_only", "Por favor seleccione um valor da lista.", "validators", "pt");
    Translator.add("profile.image.error", "Imagem inv\u00e1lida. <br \/>Ter\u00e1 que ter no m\u00ednimo 300x300 px e menos que 1MB", "validators", "pt");
    Translator.add("range.min", "Este valor precisar ser {{ limit }} ou superior.", "validators", "pt");
    Translator.add("range.max", "Este valor precisar ser {{ limit }} ou inferior.", "validators", "pt");
    Translator.add("rating_value.range", "S\u00f3 s\u00e3o v\u00e1lidos valores de 1 a 5.", "validators", "pt");
    Translator.add("url_collision", "Poss\u00edvel colis\u00e3o de URL com \"%string%\". Experimente subsituir todas as \"\/\" por \"-\"", "validators", "pt");
    Translator.add("validation.too_short", "O texto \u00e9 muito curto.", "validators", "pt");
    Translator.add("validation.too_long", "O texto \u00e9 muito longo.", "validators", "pt");
})(Translator);
