(function (Translator) {
    // en
    Translator.add("sonata_administration", "Administration", "SonataAdminBundle", "en");
    Translator.add("action_delete", "Delete", "SonataAdminBundle", "en");
    Translator.add("btn_batch", "OK", "SonataAdminBundle", "en");
    Translator.add("btn_create", "Create", "SonataAdminBundle", "en");
    Translator.add("btn_create_and_edit_again", "Create", "SonataAdminBundle", "en");
    Translator.add("btn_create_and_create_a_new_one", "Create and add another", "SonataAdminBundle", "en");
    Translator.add("btn_create_and_return_to_list", "Create and return to list", "SonataAdminBundle", "en");
    Translator.add("btn_filter", "Filter", "SonataAdminBundle", "en");
    Translator.add("btn_advanced_filters", "Advanced filters", "SonataAdminBundle", "en");
    Translator.add("btn_update", "Update", "SonataAdminBundle", "en");
    Translator.add("btn_update_and_edit_again", "Update", "SonataAdminBundle", "en");
    Translator.add("btn_update_and_return_to_list", "Update and close", "SonataAdminBundle", "en");
    Translator.add("link_delete", "Delete", "SonataAdminBundle", "en");
    Translator.add("link_action_create", "Add new", "SonataAdminBundle", "en");
    Translator.add("link_action_list", "Return to list", "SonataAdminBundle", "en");
    Translator.add("link_action_show", "Show", "SonataAdminBundle", "en");
    Translator.add("link_action_edit", "Edit", "SonataAdminBundle", "en");
    Translator.add("link_add", "Add new", "SonataAdminBundle", "en");
    Translator.add("link_list", "List", "SonataAdminBundle", "en");
    Translator.add("link_reset_filter", "Reset", "SonataAdminBundle", "en");
    Translator.add("title_create", "Create", "SonataAdminBundle", "en");
    Translator.add("title_dashboard", "Dashboard", "SonataAdminBundle", "en");
    Translator.add("title_edit", "Edit \"%name%\"", "SonataAdminBundle", "en");
    Translator.add("title_list", "List", "SonataAdminBundle", "en");
    Translator.add("link_next_pager", "Next", "SonataAdminBundle", "en");
    Translator.add("link_previous_pager", "Previous", "SonataAdminBundle", "en");
    Translator.add("link_first_pager", "First", "SonataAdminBundle", "en");
    Translator.add("link_last_pager", "Last", "SonataAdminBundle", "en");
    Translator.add("Admin", "Admin", "SonataAdminBundle", "en");
    Translator.add("link_expand", "expand\/collapse", "SonataAdminBundle", "en");
    Translator.add("no_result", "No result", "SonataAdminBundle", "en");
    Translator.add("confirm_msg", "Are you sure ?", "SonataAdminBundle", "en");
    Translator.add("action_edit", "Edit", "SonataAdminBundle", "en");
    Translator.add("action_show", "Show", "SonataAdminBundle", "en");
    Translator.add("all_elements", "All elements", "SonataAdminBundle", "en");
    Translator.add("flash_batch_empty", "Action aborted. No items were selected.", "SonataAdminBundle", "en");
    Translator.add("flash_create_success", "Item \"%name%\" has been successfully created.", "SonataAdminBundle", "en");
    Translator.add("flash_create_error", "An error has occurred during the creation of item \"%name%\".", "SonataAdminBundle", "en");
    Translator.add("flash_edit_success", "Item \"%name%\" has been successfully updated.", "SonataAdminBundle", "en");
    Translator.add("flash_edit_error", "An error has occurred during update of item \"%name%\".", "SonataAdminBundle", "en");
    Translator.add("flash_lock_error", "Another user has modified item \"%name%\". Please %link_start%click here%link_end% to reload the page and apply the changes again.", "SonataAdminBundle", "en");
    Translator.add("flash_batch_delete_success", "Selected items have been successfully deleted.", "SonataAdminBundle", "en");
    Translator.add("flash_batch_delete_error", "An Error has occurred during selected items deletion.", "SonataAdminBundle", "en");
    Translator.add("flash_delete_error", "An Error has occurred during deletion of item \"%name%\".", "SonataAdminBundle", "en");
    Translator.add("flash_delete_success", "Item \"%name%\" has been deleted successfully.", "SonataAdminBundle", "en");
    Translator.add("form_not_available", "Form is not available.", "SonataAdminBundle", "en");
    Translator.add("breadcrumb.link_dashboard", "<i class=\"fa fa-home\"><\/i>", "SonataAdminBundle", "en");
    Translator.add("title_delete", "Confirm deletion", "SonataAdminBundle", "en");
    Translator.add("message_delete_confirmation", "Are you sure you want to delete the selected \"%object%\" element?", "SonataAdminBundle", "en");
    Translator.add("btn_delete", "Yes, delete", "SonataAdminBundle", "en");
    Translator.add("title_batch_confirmation", "Confirm batch action '%action%'", "SonataAdminBundle", "en");
    Translator.add("message_batch_confirmation", "Are you sure you want to confirm this action and execute it for the selected element?|Are you sure you want to confirm this action and execute it for the %count% selected elements?", "SonataAdminBundle", "en");
    Translator.add("message_batch_all_confirmation", "Are you sure you want to confirm this action and execute it for all the elements?", "SonataAdminBundle", "en");
    Translator.add("btn_execute_batch_action", "Yes, execute", "SonataAdminBundle", "en");
    Translator.add("label_type_yes", "yes", "SonataAdminBundle", "en");
    Translator.add("label_type_no", "no", "SonataAdminBundle", "en");
    Translator.add("label_type_contains", "contains", "SonataAdminBundle", "en");
    Translator.add("label_type_not_contains", "does not contain", "SonataAdminBundle", "en");
    Translator.add("label_type_equals", "is equal to", "SonataAdminBundle", "en");
    Translator.add("label_type_not_equals", "is not equal to", "SonataAdminBundle", "en");
    Translator.add("label_type_equal", "=", "SonataAdminBundle", "en");
    Translator.add("label_type_greater_equal", ">=", "SonataAdminBundle", "en");
    Translator.add("label_type_greater_than", ">", "SonataAdminBundle", "en");
    Translator.add("label_type_less_equal", "<=", "SonataAdminBundle", "en");
    Translator.add("label_type_less_than", "<", "SonataAdminBundle", "en");
    Translator.add("label_date_type_equal", "=", "SonataAdminBundle", "en");
    Translator.add("label_date_type_greater_equal", ">=", "SonataAdminBundle", "en");
    Translator.add("label_date_type_greater_than", ">", "SonataAdminBundle", "en");
    Translator.add("label_date_type_less_equal", "<=", "SonataAdminBundle", "en");
    Translator.add("label_date_type_less_than", "<", "SonataAdminBundle", "en");
    Translator.add("label_date_type_null", "is empty", "SonataAdminBundle", "en");
    Translator.add("label_date_type_not_null", "is not empty", "SonataAdminBundle", "en");
    Translator.add("label_date_type_between", "between", "SonataAdminBundle", "en");
    Translator.add("label_date_type_not_between", "not between", "SonataAdminBundle", "en");
    Translator.add("label_filters", "Filters", "SonataAdminBundle", "en");
    Translator.add("delete_or", "or", "SonataAdminBundle", "en");
    Translator.add("link_action_history", "Revisions", "SonataAdminBundle", "en");
    Translator.add("td_action", "Action", "SonataAdminBundle", "en");
    Translator.add("td_compare", "Compare", "SonataAdminBundle", "en");
    Translator.add("td_revision", "Revisions", "SonataAdminBundle", "en");
    Translator.add("td_timestamp", "Date", "SonataAdminBundle", "en");
    Translator.add("td_username", "Author", "SonataAdminBundle", "en");
    Translator.add("td_role", "Role", "SonataAdminBundle", "en");
    Translator.add("label_view_revision", "View Revision", "SonataAdminBundle", "en");
    Translator.add("label_compare_revision", "Compare revision", "SonataAdminBundle", "en");
    Translator.add("list_results_count_prefix", "at least", "SonataAdminBundle", "en");
    Translator.add("list_results_count", "1 result|%count% results", "SonataAdminBundle", "en");
    Translator.add("label_export_download", "Download", "SonataAdminBundle", "en");
    Translator.add("export_format_json", "JSON", "SonataAdminBundle", "en");
    Translator.add("export_format_xml", "XML", "SonataAdminBundle", "en");
    Translator.add("export_format_csv", "CSV", "SonataAdminBundle", "en");
    Translator.add("export_format_xls", "XLS", "SonataAdminBundle", "en");
    Translator.add("loading_information", "Loading information\u2026", "SonataAdminBundle", "en");
    Translator.add("btn_preview", "Preview", "SonataAdminBundle", "en");
    Translator.add("btn_preview_approve", "Approve", "SonataAdminBundle", "en");
    Translator.add("btn_preview_decline", "Decline", "SonataAdminBundle", "en");
    Translator.add("label_per_page", "Per page", "SonataAdminBundle", "en");
    Translator.add("list_select", "Select", "SonataAdminBundle", "en");
    Translator.add("confirm_exit", "You have unsaved changes.", "SonataAdminBundle", "en");
    Translator.add("link_edit_acl", "Edit ACL", "SonataAdminBundle", "en");
    Translator.add("btn_update_acl", "Update ACL", "SonataAdminBundle", "en");
    Translator.add("flash_acl_edit_success", "ACL has been successfuly updated.", "SonataAdminBundle", "en");
    Translator.add("link_action_acl", "ACL", "SonataAdminBundle", "en");
    Translator.add("short_object_description_placeholder", "No selection", "SonataAdminBundle", "en");
    Translator.add("title_search_results", "Search results: %query%", "SonataAdminBundle", "en");
    Translator.add("search_placeholder", "Search", "SonataAdminBundle", "en");
    Translator.add("no_results_found", "no result found", "SonataAdminBundle", "en");
    Translator.add("add_new_entry", "add new entry", "SonataAdminBundle", "en");
    Translator.add("link_actions", "Actions", "SonataAdminBundle", "en");
    Translator.add("noscript_warning", "Javascript is disabled in your web browser. Some features will not work properly.", "SonataAdminBundle", "en");
    Translator.add("message_form_group_empty", "No fields available.", "SonataAdminBundle", "en");
    Translator.add("link_filters", "Filters", "SonataAdminBundle", "en");
    Translator.add("stats_view_more", "View more", "SonataAdminBundle", "en");
    Translator.add("title_select_subclass", "Select object type", "SonataAdminBundle", "en");
    Translator.add("no_subclass_available", "No object types available", "SonataAdminBundle", "en");
})(Translator);
