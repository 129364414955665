(function (Translator) {
    // en
    Translator.add("Bad credentials", "Invalid username or password", "FOSUserBundle", "en");
    Translator.add("group.edit.submit", "Update group", "FOSUserBundle", "en");
    Translator.add("group.show.name", "Group name", "FOSUserBundle", "en");
    Translator.add("group.new.submit", "Create group", "FOSUserBundle", "en");
    Translator.add("group.flash.updated", "The group has been updated", "FOSUserBundle", "en");
    Translator.add("group.flash.created", "The group has been created", "FOSUserBundle", "en");
    Translator.add("group.flash.deleted", "The group has been deleted", "FOSUserBundle", "en");
    Translator.add("security.login.username", "Username:", "FOSUserBundle", "en");
    Translator.add("security.login.password", "Password:", "FOSUserBundle", "en");
    Translator.add("security.login.remember_me", "Remember me", "FOSUserBundle", "en");
    Translator.add("security.login.submit", "Login", "FOSUserBundle", "en");
    Translator.add("profile.show.username", "Username", "FOSUserBundle", "en");
    Translator.add("profile.show.email", "Email", "FOSUserBundle", "en");
    Translator.add("profile.edit.submit", "Update", "FOSUserBundle", "en");
    Translator.add("profile.flash.updated", "The profile has been updated", "FOSUserBundle", "en");
    Translator.add("change_password.submit", "Change password", "FOSUserBundle", "en");
    Translator.add("change_password.flash.success", "The password has been changed", "FOSUserBundle", "en");
    Translator.add("registration.check_email", "An email has been sent to %email%. It contains an activation link you must click to activate your account.", "FOSUserBundle", "en");
    Translator.add("registration.confirmed", "Congrats %username%, your account is now activated.", "FOSUserBundle", "en");
    Translator.add("registration.back", "Back to the originating page.", "FOSUserBundle", "en");
    Translator.add("registration.submit", "Register", "FOSUserBundle", "en");
    Translator.add("registration.flash.user_created", "The user has been created successfully", "FOSUserBundle", "en");
    Translator.add("registration.email.subject", "Welcome %username%!", "FOSUserBundle", "en");
    Translator.add("registration.email.message", "Hello %username%!\n\nTo finish activating your account - please visit %confirmationUrl%\n\nRegards,\nthe Team.\n", "FOSUserBundle", "en");
    Translator.add("resetting.password_already_requested", "The password for this user has already been requested within the last 24 hours.", "FOSUserBundle", "en");
    Translator.add("resetting.check_email", "An email has been sent to %email%. It contains a link you must click to reset your password.", "FOSUserBundle", "en");
    Translator.add("resetting.request.invalid_username", "The username or email address \"%username%\" does not exist.", "FOSUserBundle", "en");
    Translator.add("resetting.request.username", "Username or email address:", "FOSUserBundle", "en");
    Translator.add("resetting.request.submit", "Reset password", "FOSUserBundle", "en");
    Translator.add("resetting.reset.submit", "Change password", "FOSUserBundle", "en");
    Translator.add("resetting.flash.success", "The password has been reset successfully", "FOSUserBundle", "en");
    Translator.add("resetting.email.subject", "Reset Password", "FOSUserBundle", "en");
    Translator.add("resetting.email.message", "Hello %username%!\n\nTo reset your password - please visit %confirmationUrl%\n\nRegards,\nthe Team.\n", "FOSUserBundle", "en");
    Translator.add("layout.logout", "Logout", "FOSUserBundle", "en");
    Translator.add("layout.login", "Login", "FOSUserBundle", "en");
    Translator.add("layout.register", "Register", "FOSUserBundle", "en");
    Translator.add("layout.logged_in_as", "Logged in as %username%", "FOSUserBundle", "en");
    Translator.add("form.group_name", "Group name:", "FOSUserBundle", "en");
    Translator.add("form.username", "Username", "FOSUserBundle", "en");
    Translator.add("form.email", "Email", "FOSUserBundle", "en");
    Translator.add("form.current_password", "Current password", "FOSUserBundle", "en");
    Translator.add("form.password", "Password", "FOSUserBundle", "en");
    Translator.add("form.password_confirmation", "Verification", "FOSUserBundle", "en");
    Translator.add("form.new_password", "New password", "FOSUserBundle", "en");
    Translator.add("form.new_password_confirmation", "Verification", "FOSUserBundle", "en");
})(Translator);
