(function (Translator) {
    // en
    Translator.add("welcome.subject", "Ol\u00e1, %name%!", "emails", "en");
    Translator.add("welcome.body.know_more", "Descubra mais", "emails", "en");
    Translator.add("welcome.body.top", "Ol\u00e1, %name%!<br \/>Bem-vindo a Belenze a maior plataforma de beleza do Brazil!<br \/>", "emails", "en");
    Translator.add("welcome.body.center", "<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.<\/p>", "emails", "en");
    Translator.add("resseting.email.subject", "Redefinir sua senha em Ignite Your ProjectPlatform", "emails", "en");
    Translator.add("resseting.email.message", "Ol\u00e1, %name%!<br \/><br \/>\nPara redefinir sua senha, clique no link: <a href=\"%confirmationUrl%\" target=\"_blank\">%confirmationUrl%<\/a><br \/><br \/>\nAtenciosamente,<br \/>\nIgnite Your Project Platform team\n", "emails", "en");
    Translator.add("logo.inline", "email\/logo\/test\/logo_inline.png", "emails", "en");
    Translator.add("contact_us.subject", "There a contact requested from email %email%", "emails", "en");
    Translator.add("contact_us.body.header", "Contact details:", "emails", "en");
    Translator.add("contact_us.body.part_1", "Name: <b>%name%<\/b> <br \/> Phone: <b>%phone%<\/b> <br \/> Email <b>%email%<\/b> <br \/>", "emails", "en");
    Translator.add("contact_us.body.part_2", "Message: <br \/>", "emails", "en");
    Translator.add("presentation.subject", "Greatings from Web Development && Digital Marketing Revolution!", "emails", "en");
    Translator.add("presentation.body", "Greatings,<br \/>\n<br \/>\nQuality matters, that's why we are here.<br \/>\nWe will provide you the right tools for your business to grow on the digital economy, automate your company your company processes, create brand awareness, connecting your business with the right people and make all lives easier.<br \/>\n<br \/>\nCheck more about us on the link below.<br \/>\n", "emails", "en");
    Translator.add("presentation.link", "<a href=\"https:\/\/igniteyourproject.com\/document\/presentation.pdf\" >view presentation<\/a>", "emails", "en");
    Translator.add("presentation.header", "IGNITE <span class=\"text-grey\">YOUR<\/span> PROJECT", "emails", "en");
    Translator.add("presentation.subheader", "Web Development, Digital Marketing", "emails", "en");
    Translator.add("presentation.signature", "In\u00eas Santos<br \/>\nBusiness Director| <a href=\"https:\/\/IgniteYourProject.com\" target=\"_blank\">www.IgniteYourProject.com<\/a><br \/>\nTel: <a class=\"phone\" href=\"tel:%2B442036080545\" value=\"+44 203 60 80 545\" target=\"_blank\">+44 203 60 80 545<\/a>\n| Tel: <a class=\"phone\" href=\"tel:%2B351 30 880 34 17\" value=\"+351 30 880 34 17\" target=\"_blank\">+351 30 880 34 17<\/a><br \/>\nEmail: <a href=\"mailto:hello@IgniteYourProject.com\" target=\"_blank\">hello@IgniteYourProject.com<\/a><br \/>\n<br \/>\nFollow us on:<br \/>\n<a href=\"https:\/\/www.linkedin.com\/company\/ignite-your-project\" target=\"_blank\">LinkedIn<\/a>\n| <a href=\"https:\/\/twitter.com\/IgniteYproject\" target=\"_blank\">Twitter (@IgniteYproject)<\/a>\n| <a href=\"https:\/\/www.facebook.com\/ignite.your.project\" target=\"_blank\">Facebook<\/a>\n<br \/>\n", "emails", "en");
    Translator.add("budget_request.subject", "Pedido de or\u00e7amento de %name% para %restaurant%", "emails", "en");
    Translator.add("budget_request.title", "Novo pedido de or\u00e7amento", "emails", "en");
    Translator.add("budget_request.body.name", "Nome: %name%", "emails", "en");
    Translator.add("budget_request.body.phone", "Telefone: %phone%", "emails", "en");
    Translator.add("budget_request.body.email", "Email: %email%", "emails", "en");
    Translator.add("budget_request.body.date", "Data prevista: %date%", "emails", "en");
    Translator.add("budget_request.body.hour", "Hora prevista: %hour%", "emails", "en");
    Translator.add("budget_request.body.number_of_people", "N\u00famero de pessoas: %number_of_people%", "emails", "en");
    Translator.add("budget_request.body.type", "Tipo: %type%", "emails", "en");
    Translator.add("budget_request.body.max_price_per_person", "Pre\u00e7o m\u00e1ximo p\/ pessoa: %max_price_per_person%", "emails", "en");
    Translator.add("budget_request.body.restaurant", "Restaurante: %restaurant%", "emails", "en");
    Translator.add("budget_request.body.more_details", "Mais Detalhes: %more_details%", "emails", "en");
    Translator.add("new_points.subject", "Obrigado pelo seu feedback", "emails", "en");
    Translator.add("new_points.title", "Parab\u00e9ns %name%,", "emails", "en");
    Translator.add("new_points.body", "acaba de ganhar 10 pontos A Cascata!", "emails", "en");
})(Translator);
