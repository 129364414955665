(function (Translator) {
    // en
    Translator.add("action_edit", "Edit", "admin", "en");
    Translator.add("action_delete", "Delete", "admin", "en");
    Translator.add("admin.administration", "Administration", "admin", "en");
    Translator.add("admin.cms_content", "CMS Content", "admin", "en");
    Translator.add("admin.cms_gallery", "CMS Images", "admin", "en");
    Translator.add("admin.gallery.homepage", "Homepage Gallery", "admin", "en");
    Translator.add("admin.faqs", "FAQs", "admin", "en");
    Translator.add("admin.info", "Info", "admin", "en");
    Translator.add("admin.manager", "Manager", "admin", "en");
    Translator.add("admin.others", "Others", "admin", "en");
    Translator.add("admin.picture", "Picture", "admin", "en");
    Translator.add("admin.products_and_categories", "Products & Categories", "admin", "en");
    Translator.add("admin.profile", "Profile", "admin", "en");
    Translator.add("admin.seo_pages", "SEO Pages", "admin", "en");
    Translator.add("admin.user", "User", "admin", "en");
    Translator.add("admin.users", "Users", "admin", "en");
    Translator.add("allergens", "Allergens", "admin", "en");
    Translator.add("amount", "Amount", "admin", "en");
    Translator.add("attachments", "Attachments", "admin", "en");
    Translator.add("blog", "Blog", "admin", "en");
    Translator.add("breadcrumb.link_advance_configuration_list", "Advanced Configurations", "admin", "en");
    Translator.add("breadcrumb.link_blog_category_list", "Blog Categories", "admin", "en");
    Translator.add("breadcrumb.link_blog_list", "Blog Posts", "admin", "en");
    Translator.add("breadcrumb.link_category_list", "Categories", "admin", "en");
    Translator.add("breadcrumb.link_cms_page_list", "Cms_Pages", "admin", "en");
    Translator.add("breadcrumb.link_f_a_q_category_list", "FAQ Categories", "admin", "en");
    Translator.add("breadcrumb.link_f_a_q_list", "FAQs", "admin", "en");
    Translator.add("breadcrumb.link_gallery_list", "Galleries", "admin", "en");
    Translator.add("breadcrumb.link_partnership_list", "Partnerships", "admin", "en");
    Translator.add("breadcrumb.link_product_list", "Products", "admin", "en");
    Translator.add("breadcrumb.link_product_category_list", "Product Categories", "admin", "en");
    Translator.add("breadcrumb.link_promotion_list", "Promotions", "admin", "en");
    Translator.add("breadcrumb.link_review_list", "Reviews", "admin", "en");
    Translator.add("breadcrumb.link_seo_page_list", "SEO Pages", "admin", "en");
    Translator.add("breadcrumb.link_store_list", "Stores", "admin", "en");
    Translator.add("button.add", "Add", "admin", "en");
    Translator.add("button.browse", "Browse", "admin", "en");
    Translator.add("button.delete.question", "Remove", "admin", "en");
    Translator.add("button.list", "List", "admin", "en");
    Translator.add("category", "Category", "admin", "en");
    Translator.add("category_info", "Category Info", "admin", "en");
    Translator.add("default.img.alt", "Picture", "admin", "en");
    Translator.add("download_qr_code", "Download QR-Code", "admin", "en");
    Translator.add("filter.label_ambience", "Ambience", "admin", "en");
    Translator.add("filter.label_created_at", "Created at", "admin", "en");
    Translator.add("filter.label_category", "Category", "admin", "en");
    Translator.add("filter.label_end_date", "End date", "admin", "en");
    Translator.add("filter.label_email", "Email", "admin", "en");
    Translator.add("filter.label_enabled", "Enabled", "admin", "en");
    Translator.add("filter.label_external_key", "External Key", "admin", "en");
    Translator.add("filter.label_faq_category", "Faq category", "admin", "en");
    Translator.add("filter.label_id", "ID", "admin", "en");
    Translator.add("filter.label_name", "Name", "admin", "en");
    Translator.add("filter.label_overall", "Overall", "admin", "en");
    Translator.add("filter.label_position", "Position", "admin", "en");
    Translator.add("filter.label_price", "Price", "admin", "en");
    Translator.add("filter.label_product", "Product", "admin", "en");
    Translator.add("filter.label_quality", "Quality", "admin", "en");
    Translator.add("filter.label_store", "Store", "admin", "en");
    Translator.add("filter.label_title", "Title", "admin", "en");
    Translator.add("filter.label_translations_title", "Title", "admin", "en");
    Translator.add("filter.label_translations_url", "Url", "admin", "en");
    Translator.add("filter.label_seo_translations_url", "Url", "admin", "en");
    Translator.add("filter.label_translations_answer", "Answer", "admin", "en");
    Translator.add("filter.label_translations_question", "Question", "admin", "en");
    Translator.add("filter.label_treatment", "Treatment", "admin", "en");
    Translator.add("filter.label_type", "Type", "admin", "en");
    Translator.add("form.blog.details", "Blog Details", "admin", "en");
    Translator.add("form.extra", "Extra", "admin", "en");
    Translator.add("form.fieldset.label_general", "General", "admin", "en");
    Translator.add("form.fieldset.more_info", "More info", "admin", "en");
    Translator.add("form.fieldset.media", "Media info", "admin", "en");
    Translator.add("form.label_answer", "Answer", "admin", "en");
    Translator.add("form.label_category", "Category", "admin", "en");
    Translator.add("form.label_content", "Content", "admin", "en");
    Translator.add("form.label_content_formatter", "Content formatter", "admin", "en");
    Translator.add("form.label_credentials_expired", "Credentials Expired", "admin", "en");
    Translator.add("form.label_description", "Description", "admin", "en");
    Translator.add("form.label_duration", "Duration", "admin", "en");
    Translator.add("form.label_email", "Email", "admin", "en");
    Translator.add("form.label_end_date", "End Date", "admin", "en");
    Translator.add("form.label_enabled", "Enabled", "admin", "en");
    Translator.add("form.label_expired", "Expired", "admin", "en");
    Translator.add("form.label_faq_category", "FAQ Category", "admin", "en");
    Translator.add("form.label_formatter", "Text Formatter", "admin", "en");
    Translator.add("form.label_gallery", "Image Gallery", "admin", "en");
    Translator.add("form.label_gallery_has_medias", "Images", "admin", "en");
    Translator.add("form.label_gender", "Gender", "admin", "en");
    Translator.add("form.label_homepage", "Homepage", "admin", "en");
    Translator.add("form.label_keywords", "Keywords", "admin", "en");
    Translator.add("form.label_locked", "Locked", "admin", "en");
    Translator.add("form.label_manual_url", "Manual URL and title", "admin", "en");
    Translator.add("form.label_media", "Picture", "admin", "en");
    Translator.add("form.label_mobile", "Mobile", "admin", "en");
    Translator.add("form.label_name", "Name", "admin", "en");
    Translator.add("form.label_parent", "Parent", "admin", "en");
    Translator.add("form.label_phone", "Phone", "admin", "en");
    Translator.add("form.label_picture", "Picture", "admin", "en");
    Translator.add("form.label_position", "Position", "admin", "en");
    Translator.add("form.label_price", "Price", "admin", "en");
    Translator.add("form.label_question", "Question", "admin", "en");
    Translator.add("form.label_sub_title", "Sub-title", "admin", "en");
    Translator.add("form.label_start_date", "Start Date", "admin", "en");
    Translator.add("form.label_sku", "Unique code", "admin", "en");
    Translator.add("form.label_title", "Title", "admin", "en");
    Translator.add("form.label_type", "Type", "admin", "en");
    Translator.add("form.label_url", "URL", "admin", "en");
    Translator.add("form.address.address_line1", "Endere\u00e7o (Linha 1)", "admin", "en");
    Translator.add("form.address.address_line2", "Endere\u00e7o (Linha 2)", "admin", "en");
    Translator.add("form.address.address_line_1", "Endere\u00e7o (Linha 1)", "admin", "en");
    Translator.add("form.address.address_line_2", "Endere\u00e7o (Linha 2)", "admin", "en");
    Translator.add("form.address.postal_code", "C\u00f3digo Postal", "admin", "en");
    Translator.add("form.address.locality", "Localidade", "admin", "en");
    Translator.add("form.address.city", "Cidade", "admin", "en");
    Translator.add("form.postal_code", "C\u00f3digo Postal", "admin", "en");
    Translator.add("form.label_opening_hours_start", "Hor\u00e1rio de abertura", "admin", "en");
    Translator.add("form.label_opening_hours_close", "Hor\u00e1rio de fecho", "admin", "en");
    Translator.add("form.seo_details", "SEO Details", "admin", "en");
    Translator.add("form_page.faq", "FAQ", "admin", "en");
    Translator.add("form_page.group_seo_label", "SEO Page", "admin", "en");
    Translator.add("link_filters", "Filters", "admin", "en");
    Translator.add("list.label__action", "Action", "admin", "en");
    Translator.add("list.label_action", "Action", "admin", "en");
    Translator.add("list.label_ambience", "Ambience", "admin", "en");
    Translator.add("list.label_ambience_score", "Ambience (1~5)", "admin", "en");
    Translator.add("list.label_answer", "Answer", "admin", "en");
    Translator.add("list.label_category", "Category", "admin", "en");
    Translator.add("list.label_created_at", "Created at", "admin", "en");
    Translator.add("list.label_description", "Description", "admin", "en");
    Translator.add("list.label_duration", "Duration", "admin", "en");
    Translator.add("list.label_email", "Email", "admin", "en");
    Translator.add("list.label_enabled", "Active", "admin", "en");
    Translator.add("list.label_external_key", "External key", "admin", "en");
    Translator.add("list.label_faq_category", "FAQ Category", "admin", "en");
    Translator.add("list.label_groups", "Groups", "admin", "en");
    Translator.add("list.label_homepage", "Homepage", "admin", "en");
    Translator.add("list.label_id", "ID", "admin", "en");
    Translator.add("list.label_impersonating", "Impersonate", "admin", "en");
    Translator.add("list.label_key", "Key", "admin", "en");
    Translator.add("list.label_keywords", "Keywords", "admin", "en");
    Translator.add("list.label_locked", "Blocked", "admin", "en");
    Translator.add("list.label_mobile", "Mobile", "admin", "en");
    Translator.add("list.label_name", "Name", "admin", "en");
    Translator.add("list.label_overall", "Overall", "admin", "en");
    Translator.add("list.label_overall_score", "Overall (1~5)", "admin", "en");
    Translator.add("list.label_parent", "Parent", "admin", "en");
    Translator.add("list.label_phone", "Phone", "admin", "en");
    Translator.add("list.label_position", "Position", "admin", "en");
    Translator.add("list.label_price", "Price", "admin", "en");
    Translator.add("list.label_price_score", "Price (1~5)", "admin", "en");
    Translator.add("list.label_product", "Product", "admin", "en");
    Translator.add("list.label_quality", "Quality", "admin", "en");
    Translator.add("list.label_quality_score", "Quality (1~5)", "admin", "en");
    Translator.add("list.label_question", "Question", "admin", "en");
    Translator.add("list.label_seo_url", "Seo Url", "admin", "en");
    Translator.add("list.label_start_date", "Start Date", "admin", "en");
    Translator.add("list.label_end_date", "End Date", "admin", "en");
    Translator.add("list.label_status", "Status", "admin", "en");
    Translator.add("list.label_store", "Store", "admin", "en");
    Translator.add("list.label_title", "Title", "admin", "en");
    Translator.add("list.label_translations_answer", "Answer", "admin", "en");
    Translator.add("list.label_translations_summary", "Summary", "admin", "en");
    Translator.add("list.label_translations_title", "Title", "admin", "en");
    Translator.add("list.label_translations_url", "Url", "admin", "en");
    Translator.add("list.label_translations_description", "Description", "admin", "en");
    Translator.add("list.label_translations_keywords", "Keywords", "admin", "en");
    Translator.add("list.label_translations_question", "Question", "admin", "en");
    Translator.add("list.label_translations_price", "Price", "admin", "en");
    Translator.add("list.label_treatment", "Treatment", "admin", "en");
    Translator.add("list.label_treatment_score", "Treatment (1~5)", "admin", "en");
    Translator.add("list.label_type", "Type", "admin", "en");
    Translator.add("list.label_sku", "Unique code", "admin", "en");
    Translator.add("list.label_url", "URL", "admin", "en");
    Translator.add("list.label_user", "User", "admin", "en");
    Translator.add("list.label_value", "Value", "admin", "en");
    Translator.add("locale.select.all", "All", "admin", "en");
    Translator.add("message_form_group_empty", "NO DATA", "admin", "en");
    Translator.add("no_data", "N\/A", "admin", "en");
    Translator.add("nutritional_values", "Nutritional Values", "admin", "en");
    Translator.add("partnership", "Partnerships", "admin", "en");
    Translator.add("placeholder.id_media", "Media id #%id%", "admin", "en");
    Translator.add("placeholder.no_media", "No Media", "admin", "en");
    Translator.add("product", "Products", "admin", "en");
    Translator.add("products_and_categories", "Products & Categories", "admin", "en");
    Translator.add("product_category", "Product Categories", "admin", "en");
    Translator.add("product_info", "Product Info", "admin", "en");
    Translator.add("product_pictures", "Product Pictures", "admin", "en");
    Translator.add("promotion", "Promotions", "admin", "en");
    Translator.add("recent_blog_posts", "Recent Blog posts", "admin", "en");
    Translator.add("recent_reviews", "Recent Reviews", "admin", "en");
    Translator.add("reviews", "Reviews", "admin", "en");
    Translator.add("reviews_average", "Reviews Average", "admin", "en");
    Translator.add("search.address", "Search here", "admin", "en");
    Translator.add("seo_page", "SEO", "admin", "en");
    Translator.add("seo_page.description", "Page Description", "admin", "en");
    Translator.add("seo.description", "Page Description", "admin", "en");
    Translator.add("seo_page.manual", "Manual URL and title", "admin", "en");
    Translator.add("seo.manual", "Manual URL and title", "admin", "en");
    Translator.add("seo_page.keywords", "Page Keywords", "admin", "en");
    Translator.add("seo.keywords", "Page Keywords", "admin", "en");
    Translator.add("seo_page.title", "Page Title", "admin", "en");
    Translator.add("seo_page.url", "Page URL", "admin", "en");
    Translator.add("show.label_ambience", "Ambience (1 ~ 5)", "admin", "en");
    Translator.add("show.label_id", "ID", "admin", "en");
    Translator.add("show.label_overall", "Overall", "admin", "en");
    Translator.add("show.label_price", "Price (1 ~ 5)", "admin", "en");
    Translator.add("show.label_product", "Product", "admin", "en");
    Translator.add("show.label_quality", "Quality (1 ~ 5)", "admin", "en");
    Translator.add("show.label_store", "Store", "admin", "en");
    Translator.add("show.label_treatment", "Treatment (1 ~ 5)", "admin", "en");
    Translator.add("show.label_note", "Obs.", "admin", "en");
    Translator.add("side_menu.advanced_configurations", "Advanced Configurations", "admin", "en");
    Translator.add("side_menu.blog", "Blog Posts", "admin", "en");
    Translator.add("side_menu.blog_category", "Blog Categories", "admin", "en");
    Translator.add("side_menu.categories", "Categories", "admin", "en");
    Translator.add("side_menu.cms_galleries", "CMS Galleries", "admin", "en");
    Translator.add("side_menu.cms_pages", "CMS Pages", "admin", "en");
    Translator.add("side_menu.collections", "Collections", "admin", "en");
    Translator.add("side_menu.faq_categories", "FAQ Categories", "admin", "en");
    Translator.add("side_menu.faqs", "FAQs", "admin", "en");
    Translator.add("side_menu.payment", "Payments", "admin", "en");
    Translator.add("side_menu.products", "Products", "admin", "en");
    Translator.add("side_menu.seo", "SEO Pages", "admin", "en");
    Translator.add("store", "Stores", "admin", "en");
    Translator.add("store.info", "Store Info", "admin", "en");
    Translator.add("store.location", "Store Location", "admin", "en");
    Translator.add("stores", "Stores", "admin", "en");
    Translator.add("subTitle", "Sub Title", "admin", "en");
    Translator.add("title", "Title", "admin", "en");
    Translator.add("total", "Total", "admin", "en");
    Translator.add("total_facebook_users", "Total Facebook Users", "admin", "en");
    Translator.add("total_google_users", "Total Google+ Users", "admin", "en");
    Translator.add("total_reviews", "Total Reviews", "admin", "en");
    Translator.add("type", "Type", "admin", "en");
    Translator.add("url", "URL", "admin", "en");
    Translator.add("users", "Users", "admin", "en");
    Translator.add("view_all_blog_posts", "View all Blog posts", "admin", "en");
    Translator.add("view_all_reviews", "View all Reviews", "admin", "en");
})(Translator);
