(function (Translator) {
    // en
    Translator.add("default", "Default", "SonataMediaBundle", "en");
    Translator.add("media", "Media", "SonataMediaBundle", "en");
    Translator.add("gallery", "Gallery", "SonataMediaBundle", "en");
    Translator.add("sonata_media", "Media Library", "SonataMediaBundle", "en");
    Translator.add("no_provider_available", "No provider available", "SonataMediaBundle", "en");
    Translator.add("title_select_provider", "Select media provider", "SonataMediaBundle", "en");
    Translator.add("breadcrumb.link_media_create", "Create", "SonataMediaBundle", "en");
    Translator.add("breadcrumb.link_media_list", "Media", "SonataMediaBundle", "en");
    Translator.add("breadcrumb.link_media_edit", "Edit", "SonataMediaBundle", "en");
    Translator.add("breadcrumb.link_media_view", "View", "SonataMediaBundle", "en");
    Translator.add("breadcrumb.link_media_delete", "Delete", "SonataMediaBundle", "en");
    Translator.add("breadcrumb.link_gallery_create", "Create", "SonataMediaBundle", "en");
    Translator.add("breadcrumb.link_media_show", "Show", "SonataMediaBundle", "en");
    Translator.add("breadcrumb.link_gallery_list", "Galleries", "SonataMediaBundle", "en");
    Translator.add("breadcrumb.link_gallery_edit", "Edit", "SonataMediaBundle", "en");
    Translator.add("breadcrumb.link_gallery_delete", "Delete", "SonataMediaBundle", "en");
    Translator.add("description.roles_download_strategy", "The media can be retrieved by users with the following roles : %roles%.", "SonataMediaBundle", "en");
    Translator.add("description.public_download_strategy", "The media can be retrieved by anyone.", "SonataMediaBundle", "en");
    Translator.add("description.forbidden_download_strategy", "The media can be retrieved by nobody.", "SonataMediaBundle", "en");
    Translator.add("filter.label_name", "Name", "SonataMediaBundle", "en");
    Translator.add("filter.label_provider_reference", "Provider Reference", "SonataMediaBundle", "en");
    Translator.add("filter.label_enabled", "Enabled", "SonataMediaBundle", "en");
    Translator.add("filter.label_context", "Context", "SonataMediaBundle", "en");
    Translator.add("filter.label_provider_name", "Provider Name", "SonataMediaBundle", "en");
    Translator.add("filter.label_category", "filter.label_category", "SonataMediaBundle", "en");
    Translator.add("filter.label_author_name", "Author name", "SonataMediaBundle", "en");
    Translator.add("filter.label_width", "Width", "SonataMediaBundle", "en");
    Translator.add("filter.label_height", "Height", "SonataMediaBundle", "en");
    Translator.add("filter.label_content_type", "Content type", "SonataMediaBundle", "en");
    Translator.add("form.label_enabled", "Enabled", "SonataMediaBundle", "en");
    Translator.add("form.label_media", "Media", "SonataMediaBundle", "en");
    Translator.add("form.label_position", "Position", "SonataMediaBundle", "en");
    Translator.add("form.label_context", "Context", "SonataMediaBundle", "en");
    Translator.add("form.label_name", "Name", "SonataMediaBundle", "en");
    Translator.add("form.label_default_format", "Format", "SonataMediaBundle", "en");
    Translator.add("form.label_gallery_has_medias", "Media", "SonataMediaBundle", "en");
    Translator.add("form.label_author_name", "Author", "SonataMediaBundle", "en");
    Translator.add("form.label_cdn_is_flushable", "Flush CDN", "SonataMediaBundle", "en");
    Translator.add("form.label_description", "Description", "SonataMediaBundle", "en");
    Translator.add("form.label_copyright", "Copyright", "SonataMediaBundle", "en");
    Translator.add("form.label_binary_content", "File\/Reference", "SonataMediaBundle", "en");
    Translator.add("label.size", "Size", "SonataMediaBundle", "en");
    Translator.add("label.length", "Length", "SonataMediaBundle", "en");
    Translator.add("label.width", "Width", "SonataMediaBundle", "en");
    Translator.add("label.height", "Height", "SonataMediaBundle", "en");
    Translator.add("label.content_type", "Content type", "SonataMediaBundle", "en");
    Translator.add("label.copyright", "Copyright", "SonataMediaBundle", "en");
    Translator.add("label.author_name", "Author", "SonataMediaBundle", "en");
    Translator.add("label.cdn", "CDN", "SonataMediaBundle", "en");
    Translator.add("label.to_be_flushed", "Flushable", "SonataMediaBundle", "en");
    Translator.add("label.flushed_at", "Flushed at", "SonataMediaBundle", "en");
    Translator.add("label.public_url", "Public URL", "SonataMediaBundle", "en");
    Translator.add("label.name", "Name", "SonataMediaBundle", "en");
    Translator.add("label.file", "File", "SonataMediaBundle", "en");
    Translator.add("label.author", "Author", "SonataMediaBundle", "en");
    Translator.add("label.enabled", "Enabled", "SonataMediaBundle", "en");
    Translator.add("label.cdn_is_flushable", "Flush CDN", "SonataMediaBundle", "en");
    Translator.add("label.cdn_flush_identifier", "CDN flush identifier", "SonataMediaBundle", "en");
    Translator.add("label.video_reference", "Change video", "SonataMediaBundle", "en");
    Translator.add("label.description", "Description", "SonataMediaBundle", "en");
    Translator.add("label.select_context", "Context: ", "SonataMediaBundle", "en");
    Translator.add("label.select_provider", "Type : ", "SonataMediaBundle", "en");
    Translator.add("label.protected_download_url", "Protected URL", "SonataMediaBundle", "en");
    Translator.add("label.protected_download_url_notice", "Warning", "SonataMediaBundle", "en");
    Translator.add("label.edit_with_pixlr", "Edit this media with Pixlr", "SonataMediaBundle", "en");
    Translator.add("label.pixlr_warning", "\n                    \n                        <span class=\"label label-warning\">Important<\/span> you are going to expose the media to an <strong>external service<\/strong>. <br \/>\n                        If you are working on confidentiel materials, please do not use this service.\n                    \n                ", "SonataMediaBundle", "en");
    Translator.add("label.pixlr_express_editor", "\n                    \n                        <strong>Pixlr Express Editor<\/strong> <br \/>\n                        Simple interface for quick editing\n                    \n                ", "SonataMediaBundle", "en");
    Translator.add("label.pixlr_advanced_editor", "\n                    \n                        <strong>Pixlr Advanced Editor<\/strong> <br \/>\n                        Experimented users only\n                    \n                ", "SonataMediaBundle", "en");
    Translator.add("link.all_providers", "All", "SonataMediaBundle", "en");
    Translator.add("link.test_protected_url", "Link Preview", "SonataMediaBundle", "en");
    Translator.add("list.label_enabled", "Enabled", "SonataMediaBundle", "en");
    Translator.add("list.label_name", "Name", "SonataMediaBundle", "en");
    Translator.add("list.label_author_name", "Author name", "SonataMediaBundle", "en");
    Translator.add("list.label_context", "Context", "SonataMediaBundle", "en");
    Translator.add("list.label_default_format", "Format", "SonataMediaBundle", "en");
    Translator.add("list.label__action", "Action", "SonataMediaBundle", "en");
    Translator.add("list.label_custom", "Custom", "SonataMediaBundle", "en");
    Translator.add("list.label_author", "list.label_author", "SonataMediaBundle", "en");
    Translator.add("sidemenu.link_edit_media", "Edit", "SonataMediaBundle", "en");
    Translator.add("sidemenu.link_media_view", "View", "SonataMediaBundle", "en");
    Translator.add("sonata.media.provider.dailymotion", "DailyMotion", "SonataMediaBundle", "en");
    Translator.add("sonata.media.provider.youtube", "YouTube", "SonataMediaBundle", "en");
    Translator.add("sonata.media.provider.vimeo", "Vimeo", "SonataMediaBundle", "en");
    Translator.add("sonata.media.provider.image", "Image", "SonataMediaBundle", "en");
    Translator.add("sonata.media.provider.file", "File", "SonataMediaBundle", "en");
    Translator.add("sonata.media.provider.dailymotion.description", "Add a video hosted on DailyMotion", "SonataMediaBundle", "en");
    Translator.add("sonata.media.provider.youtube.description", "Add a video hosted on YouTube", "SonataMediaBundle", "en");
    Translator.add("sonata.media.provider.vimeo.description", "Add a video hosted on Vimeo", "SonataMediaBundle", "en");
    Translator.add("sonata.media.provider.image.description", "Add an image", "SonataMediaBundle", "en");
    Translator.add("sonata.media.provider.file.description", "Add any type of file", "SonataMediaBundle", "en");
    Translator.add("title.media_preview", "Preview", "SonataMediaBundle", "en");
    Translator.add("title.media_thumbnail", "Thumbnail", "SonataMediaBundle", "en");
    Translator.add("title.informations", "Information", "SonataMediaBundle", "en");
    Translator.add("title.formats", "Formats", "SonataMediaBundle", "en");
    Translator.add("link_media", "Link the object to a new media", "SonataMediaBundle", "en");
    Translator.add("no_linked_media", "No media linked to the object", "SonataMediaBundle", "en");
    Translator.add("widget_label_type", "Type", "SonataMediaBundle", "en");
    Translator.add("widget_label_context", "Context", "SonataMediaBundle", "en");
    Translator.add("widget_headline_information", "Information", "SonataMediaBundle", "en");
    Translator.add("widget_label_unlink", "Remove", "SonataMediaBundle", "en");
    Translator.add("widget_label_binary_content", "File", "SonataMediaBundle", "en");
    Translator.add("sonata_media_gallery_index", "Gallery", "SonataMediaBundle", "en");
    Translator.add("sonata_template_box_media_gallery_block", "This is the gallery media block. Feel free to override it.", "SonataMediaBundle", "en");
    Translator.add("list.label_description", "Description", "SonataMediaBundle", "en");
    Translator.add("list.label_size", "Size", "SonataMediaBundle", "en");
    Translator.add("warning_no_category", "No category defined", "SonataMediaBundle", "en");
    Translator.add("form.label_category", "Category", "SonataMediaBundle", "en");
    Translator.add("form.label_provider_reference", "Provider Reference", "SonataMediaBundle", "en");
    Translator.add("sonata.media.block.media", "Media", "SonataMediaBundle", "en");
    Translator.add("sonata.media.block.feature_media", "Feature media", "SonataMediaBundle", "en");
    Translator.add("sonata.media.block.gallery", "Media gallery", "SonataMediaBundle", "en");
    Translator.add("form.label_title", "Title", "SonataMediaBundle", "en");
    Translator.add("form.label_content", "Content", "SonataMediaBundle", "en");
    Translator.add("form.label_orientation", "Orientation", "SonataMediaBundle", "en");
    Translator.add("form.label_gallery", "Gallery", "SonataMediaBundle", "en");
    Translator.add("form.label_format", "Format", "SonataMediaBundle", "en");
    Translator.add("form.label_pause_time", "Pause time", "SonataMediaBundle", "en");
    Translator.add("form.label_start_paused", "Start paused", "SonataMediaBundle", "en");
    Translator.add("form.label_wrap", "Wrap", "SonataMediaBundle", "en");
    Translator.add("form.label_orientation_left", "Left", "SonataMediaBundle", "en");
    Translator.add("form.label_orientation_right", "Right", "SonataMediaBundle", "en");
    Translator.add("sonata.media.block.gallery_list", "Gallery list", "SonataMediaBundle", "en");
    Translator.add("label_gallery_enabled", "Enabled", "SonataMediaBundle", "en");
    Translator.add("label_gallery_disabled", "Disabled", "SonataMediaBundle", "en");
    Translator.add("no_galleries_found", "No galleries found.", "SonataMediaBundle", "en");
    Translator.add("view_all_galleries", "View all galleries", "SonataMediaBundle", "en");
    Translator.add("form.label_mode", "Mode", "SonataMediaBundle", "en");
    Translator.add("form.label_number", "Number", "SonataMediaBundle", "en");
    Translator.add("form.label_order", "Order", "SonataMediaBundle", "en");
    Translator.add("form.label_order_name", "Name", "SonataMediaBundle", "en");
    Translator.add("form.label_order_created_at", "Created at", "SonataMediaBundle", "en");
    Translator.add("form.label_order_updated_at", "Updated at", "SonataMediaBundle", "en");
    Translator.add("form.label_sort", "Sort", "SonataMediaBundle", "en");
    Translator.add("form.label_sort_asc", "Ascending", "SonataMediaBundle", "en");
    Translator.add("form.label_sort_desc", "Descending", "SonataMediaBundle", "en");
    Translator.add("form.label_mode_public", "Public", "SonataMediaBundle", "en");
    Translator.add("form.label_mode_admin", "Admin", "SonataMediaBundle", "en");
    Translator.add("form.label_class", "CSS class", "SonataMediaBundle", "en");
})(Translator);
