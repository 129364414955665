(function (Translator) {
    // en
    Translator.add("logo.inline", "logo\/logo_inline.png", "images", "en");
    Translator.add("logo", "logo\/logo.png", "images", "en");
    Translator.add("logo.alt", "A Cascata logo", "images", "en");
    Translator.add("blog.main_photo.alt", "blog main picture", "images", "en");
    Translator.add("blog.post.picture.alt", "blog post picture", "images", "en");
    Translator.add("homepage.main_photo.alt", "Beef \u00e0 cascata", "images", "en");
    Translator.add("homepage.drinks.alt", "Set of drinks in acascata", "images", "en");
    Translator.add("homepage.main_photo.big", "homepage\/main_photo.jpg", "images", "en");
})(Translator);
