(function (Translator) {
    // pt
    Translator.add("sonata_administration", "Administration", "SonataAdminBundle", "pt");
    Translator.add("action_delete", "Apagar", "SonataAdminBundle", "pt");
    Translator.add("btn_batch", "OK", "SonataAdminBundle", "pt");
    Translator.add("btn_create", "Criar", "SonataAdminBundle", "pt");
    Translator.add("btn_create_and_edit_again", "Criar e editar", "SonataAdminBundle", "pt");
    Translator.add("btn_create_and_create_a_new_one", "Criar e adicionar outro", "SonataAdminBundle", "pt");
    Translator.add("btn_create_and_return_to_list", "Criar e voltar \u00e0 lista", "SonataAdminBundle", "pt");
    Translator.add("btn_filter", "Filtrar", "SonataAdminBundle", "pt");
    Translator.add("btn_advanced_filters", "btn_advanced_filters", "SonataAdminBundle", "pt");
    Translator.add("btn_update", "Atualizar", "SonataAdminBundle", "pt");
    Translator.add("btn_update_and_edit_again", "Atualizar", "SonataAdminBundle", "pt");
    Translator.add("btn_update_and_return_to_list", "Atualizar e fechar", "SonataAdminBundle", "pt");
    Translator.add("link_delete", "Apagar", "SonataAdminBundle", "pt");
    Translator.add("link_action_create", "Adicionar novo", "SonataAdminBundle", "pt");
    Translator.add("link_action_list", "Voltar \u00e0 lista", "SonataAdminBundle", "pt");
    Translator.add("link_action_show", "Ver", "SonataAdminBundle", "pt");
    Translator.add("link_action_edit", "Editar", "SonataAdminBundle", "pt");
    Translator.add("link_add", "Novo", "SonataAdminBundle", "pt");
    Translator.add("link_list", "Listar", "SonataAdminBundle", "pt");
    Translator.add("link_reset_filter", "Redefinir", "SonataAdminBundle", "pt");
    Translator.add("title_create", "Criar", "SonataAdminBundle", "pt");
    Translator.add("title_dashboard", "Vis\u00e3o Geral", "SonataAdminBundle", "pt");
    Translator.add("title_edit", "Editar \"%name%\"", "SonataAdminBundle", "pt");
    Translator.add("title_list", "Listar", "SonataAdminBundle", "pt");
    Translator.add("link_next_pager", "Pr\u00f3ximo", "SonataAdminBundle", "pt");
    Translator.add("link_previous_pager", "Anterior", "SonataAdminBundle", "pt");
    Translator.add("link_first_pager", "Primeiro", "SonataAdminBundle", "pt");
    Translator.add("link_last_pager", "\u00daltimo", "SonataAdminBundle", "pt");
    Translator.add("Admin", "Admin", "SonataAdminBundle", "pt");
    Translator.add("link_expand", "expandir\/recolher", "SonataAdminBundle", "pt");
    Translator.add("no_result", "Sem resultados", "SonataAdminBundle", "pt");
    Translator.add("confirm_msg", "T\u00eam a certeza?", "SonataAdminBundle", "pt");
    Translator.add("action_edit", "Editar", "SonataAdminBundle", "pt");
    Translator.add("action_show", "Ver", "SonataAdminBundle", "pt");
    Translator.add("all_elements", "Todos os elementos", "SonataAdminBundle", "pt");
    Translator.add("flash_batch_empty", "A\u00e7\u00e3o abortada. Nenhum item selecionado.", "SonataAdminBundle", "pt");
    Translator.add("flash_create_success", "O item foi criado com sucesso.", "SonataAdminBundle", "pt");
    Translator.add("flash_create_error", "Ocorreu um erro durante a cria\u00e7\u00e3o do item.", "SonataAdminBundle", "pt");
    Translator.add("flash_edit_success", "O item foi atualizado com sucesso.", "SonataAdminBundle", "pt");
    Translator.add("flash_edit_error", "Ocorreu um erro durante a atualiza\u00e7\u00e3o do item.", "SonataAdminBundle", "pt");
    Translator.add("flash_lock_error", "flash_lock_error", "SonataAdminBundle", "pt");
    Translator.add("flash_batch_delete_success", "Os itens selecionados foram apagados com sucesso.", "SonataAdminBundle", "pt");
    Translator.add("flash_batch_delete_error", "Ocorreu um erro enquanto os itens selecionados eram apagados.", "SonataAdminBundle", "pt");
    Translator.add("flash_delete_error", "Ocorreu um erro enquanto o item era apagado.", "SonataAdminBundle", "pt");
    Translator.add("flash_delete_success", "O item foi apagado com sucesso.", "SonataAdminBundle", "pt");
    Translator.add("form_not_available", "form_not_available", "SonataAdminBundle", "pt");
    Translator.add("breadcrumb.link_dashboard", "Vis\u00e3o Geral", "SonataAdminBundle", "pt");
    Translator.add("title_delete", "Confirmar elimina\u00e7\u00e3o", "SonataAdminBundle", "pt");
    Translator.add("message_delete_confirmation", "Tem a certeza de que deseja eliminar o item selecionado?", "SonataAdminBundle", "pt");
    Translator.add("btn_delete", "Sim, eliminar", "SonataAdminBundle", "pt");
    Translator.add("title_batch_confirmation", "Confirmar a\u00e7\u00e3o", "SonataAdminBundle", "pt");
    Translator.add("message_batch_confirmation", "Tem a certeza de que deseja confirmar esta a\u00e7\u00e3o e execut\u00e1-la para todos os elementos selecionados?", "SonataAdminBundle", "pt");
    Translator.add("message_batch_all_confirmation", "Tem a certeza que quer confirmar esta a\u00e7\u00e3o e execut\u00e1-la para todos os elementos?", "SonataAdminBundle", "pt");
    Translator.add("btn_execute_batch_action", "Sim, executar", "SonataAdminBundle", "pt");
    Translator.add("label_type_yes", "sim", "SonataAdminBundle", "pt");
    Translator.add("label_type_no", "n\u00e3o", "SonataAdminBundle", "pt");
    Translator.add("label_type_contains", "cont\u00eam", "SonataAdminBundle", "pt");
    Translator.add("label_type_not_contains", "n\u00e3o cont\u00eam", "SonataAdminBundle", "pt");
    Translator.add("label_type_equals", "\u00e9 igual a", "SonataAdminBundle", "pt");
    Translator.add("label_type_not_equals", "n\u00e3o \u00e9 igual a", "SonataAdminBundle", "pt");
    Translator.add("label_type_equal", "=", "SonataAdminBundle", "pt");
    Translator.add("label_type_greater_equal", ">=", "SonataAdminBundle", "pt");
    Translator.add("label_type_greater_than", ">", "SonataAdminBundle", "pt");
    Translator.add("label_type_less_equal", "<=", "SonataAdminBundle", "pt");
    Translator.add("label_type_less_than", "<", "SonataAdminBundle", "pt");
    Translator.add("label_date_type_equal", "=", "SonataAdminBundle", "pt");
    Translator.add("label_date_type_greater_equal", ">=", "SonataAdminBundle", "pt");
    Translator.add("label_date_type_greater_than", ">", "SonataAdminBundle", "pt");
    Translator.add("label_date_type_less_equal", "<=", "SonataAdminBundle", "pt");
    Translator.add("label_date_type_less_than", "<", "SonataAdminBundle", "pt");
    Translator.add("label_date_type_null", "est\u00e1 vazio", "SonataAdminBundle", "pt");
    Translator.add("label_date_type_not_null", "n\u00e3o est\u00e1 vazio", "SonataAdminBundle", "pt");
    Translator.add("label_date_type_between", "entre", "SonataAdminBundle", "pt");
    Translator.add("label_date_type_not_between", "n\u00e3o entre os", "SonataAdminBundle", "pt");
    Translator.add("label_filters", "Filtros", "SonataAdminBundle", "pt");
    Translator.add("delete_or", "ou", "SonataAdminBundle", "pt");
    Translator.add("link_action_history", "Revis\u00f5es", "SonataAdminBundle", "pt");
    Translator.add("td_action", "A\u00e7\u00e3o", "SonataAdminBundle", "pt");
    Translator.add("td_compare", "td_compare", "SonataAdminBundle", "pt");
    Translator.add("td_revision", "Revis\u00f5es", "SonataAdminBundle", "pt");
    Translator.add("td_timestamp", "Data", "SonataAdminBundle", "pt");
    Translator.add("td_username", "Autor", "SonataAdminBundle", "pt");
    Translator.add("td_role", "td_role", "SonataAdminBundle", "pt");
    Translator.add("label_view_revision", "Ver Revis\u00e3o", "SonataAdminBundle", "pt");
    Translator.add("label_compare_revision", "label_compare_revision", "SonataAdminBundle", "pt");
    Translator.add("list_results_count_prefix", "list_results_count_prefix", "SonataAdminBundle", "pt");
    Translator.add("list_results_count", "1 resultado|%count% resultados", "SonataAdminBundle", "pt");
    Translator.add("label_export_download", "Download", "SonataAdminBundle", "pt");
    Translator.add("export_format_json", "JSON", "SonataAdminBundle", "pt");
    Translator.add("export_format_xml", "XML", "SonataAdminBundle", "pt");
    Translator.add("export_format_csv", "CSV", "SonataAdminBundle", "pt");
    Translator.add("export_format_xls", "XLS", "SonataAdminBundle", "pt");
    Translator.add("loading_information", "Carregando informa\u00e7\u00e3o\u2026", "SonataAdminBundle", "pt");
    Translator.add("btn_preview", "Visualizar", "SonataAdminBundle", "pt");
    Translator.add("btn_preview_approve", "Aprovado", "SonataAdminBundle", "pt");
    Translator.add("btn_preview_decline", "Rejeitar", "SonataAdminBundle", "pt");
    Translator.add("label_per_page", "Por p\u00e1gina", "SonataAdminBundle", "pt");
    Translator.add("list_select", "Selecionar", "SonataAdminBundle", "pt");
    Translator.add("confirm_exit", "confirm_exit", "SonataAdminBundle", "pt");
    Translator.add("link_edit_acl", "link_edit_acl", "SonataAdminBundle", "pt");
    Translator.add("btn_update_acl", "btn_update_acl", "SonataAdminBundle", "pt");
    Translator.add("flash_acl_edit_success", "flash_acl_update_success", "SonataAdminBundle", "pt");
    Translator.add("link_action_acl", "link_action_acl", "SonataAdminBundle", "pt");
    Translator.add("short_object_description_placeholder", "Nenhuma selec\u00e7\u00e3o", "SonataAdminBundle", "pt");
    Translator.add("title_search_results", "title_search_results", "SonataAdminBundle", "pt");
    Translator.add("search_placeholder", "search_placeholder", "SonataAdminBundle", "pt");
    Translator.add("no_results_found", "no_results_found", "SonataAdminBundle", "pt");
    Translator.add("add_new_entry", "add_new_entry", "SonataAdminBundle", "pt");
    Translator.add("link_actions", "link_actions", "SonataAdminBundle", "pt");
    Translator.add("noscript_warning", "noscript_warning", "SonataAdminBundle", "pt");
    Translator.add("message_form_group_empty", "message_form_group_empty", "SonataAdminBundle", "pt");
    Translator.add("link_filters", "link_filters", "SonataAdminBundle", "pt");
    Translator.add("stats_view_more", "stats_view_more", "SonataAdminBundle", "pt");
    Translator.add("title_select_subclass", "title_select_subclass", "SonataAdminBundle", "pt");
    Translator.add("no_subclass_available", "no_subclass_available", "SonataAdminBundle", "pt");
})(Translator);
