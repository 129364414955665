(function (Translator) {
    // en
    Translator.add("a_good", "a good", "messages", "en");
    Translator.add("a_good_", "a good ...", "messages", "en");
    Translator.add("about_us", "About Us", "messages", "en");
    Translator.add("add", "Add", "messages", "en");
    Translator.add("address", "Address", "messages", "en");
    Translator.add("address.city", "City", "messages", "en");
    Translator.add("address.edit", "Edit Address", "messages", "en");
    Translator.add("address.locality", "Locality", "messages", "en");
    Translator.add("address.postal_code", "Postal Code", "messages", "en");
    Translator.add("all_food_menus_details_available", "Full details of the available menus", "messages", "en");
    Translator.add("all_info_at_the_distance_of_one_click", "more infos with just one click", "messages", "en");
    Translator.add("allergen_1", "gluten", "messages", "en");
    Translator.add("allergen_2", "crustaceans", "messages", "en");
    Translator.add("allergen_3", "eggs", "messages", "en");
    Translator.add("allergen_4", "fish", "messages", "en");
    Translator.add("allergen_5", "peanuts", "messages", "en");
    Translator.add("allergen_6", "soybeans", "messages", "en");
    Translator.add("allergen_7", "milk", "messages", "en");
    Translator.add("allergen_8", "nuts", "messages", "en");
    Translator.add("allergen_9", "celery", "messages", "en");
    Translator.add("allergen_10", "mustard", "messages", "en");
    Translator.add("allergen_11", "sesame", "messages", "en");
    Translator.add("allergen_12", "sulphur dioxide\/sulphites > 10mg\/Kg", "messages", "en");
    Translator.add("allergen_13", "lupin", "messages", "en");
    Translator.add("allergen_14", "molluscs", "messages", "en");
    Translator.add("allergens", "Allergens", "messages", "en");
    Translator.add("ambience", "Ambience", "messages", "en");
    Translator.add("any_info_you_want_to_add", "any info you want to add?", "messages", "en");
    Translator.add("any_observation", "any observation?", "messages", "en");
    Translator.add("be_the_first_to_know_our_news", "Be the first to know our news", "messages", "en");
    Translator.add("blog", "Blog", "messages", "en");
    Translator.add("blog.header", "We share stories", "messages", "en");
    Translator.add("blog.sub_header", "good & fast restaurant", "messages", "en");
    Translator.add("blog.menu.categories", "Categories", "messages", "en");
    Translator.add("blog.menu.latest", "Recent posts", "messages", "en");
    Translator.add("browse", "Browse", "messages", "en");
    Translator.add("button.back", "Back", "messages", "en");
    Translator.add("cancel", "Cancel", "messages", "en");
    Translator.add("carbohydrates", "Carbohydrates", "messages", "en");
    Translator.add("category_picture", "Category Picture", "messages", "en");
    Translator.add("change", "Change", "messages", "en");
    Translator.add("close", "Close", "messages", "en");
    Translator.add("cookies", "This website uses cookies to ensure you get the best experience on our website. <a aria-label=\"learn more about cookies\" role=\"button\" tabindex=\"0\" class=\"cc-link\" href=\"https:\/\/cookiesandyou.com\" rel=\"noopener noreferrer nofollow\" target=\"_blank\">Learn more<\/a>", "messages", "en");
    Translator.add("contact_us.subject.1", "Customer care", "messages", "en");
    Translator.add("contact_us.subject.2", "Franchising", "messages", "en");
    Translator.add("contact_us.subject.3", "Recruitment", "messages", "en");
    Translator.add("contact_us.subject.4", "Suggestions", "messages", "en");
    Translator.add("contact_us.subject.5", "Others", "messages", "en");
    Translator.add("contacts", "Contacts", "messages", "en");
    Translator.add("country_default", "PT", "messages", "en");
    Translator.add("customer_service", "Customer service", "messages", "en");
    Translator.add("daily.intake.percentage", "% of recommended daily intake for an adult", "messages", "en");
    Translator.add("dashboard", "Dashboard", "messages", "en");
    Translator.add("day", "Day", "messages", "en");
    Translator.add("date_string", "%day% of %month%, %year%", "messages", "en");
    Translator.add("delete", "Delete", "messages", "en");
    Translator.add("description", "Description", "messages", "en");
    Translator.add("do_your_search", "do your search", "messages", "en");
    Translator.add("download", "Download", "messages", "en");
    Translator.add("DIALOGS_OK", "OK", "messages", "en");
    Translator.add("email", "Email", "messages", "en");
    Translator.add("energy", "Energy", "messages", "en");
    Translator.add("english_day", "{1,21,31} %count%st |{2,22} %count%nd |{3,23} %count%rd | [4,30] %count%th", "messages", "en");
    Translator.add("error", "Error", "messages", "en");
    Translator.add("error.ajax", "Something went wrong. Please try again.", "messages", "en");
    Translator.add("error_page.title", "Ups, an error has ocurred...", "messages", "en");
    Translator.add("error_page.sub_title", "Try going <span><a href=\"Javascript:window.history.back();\" class=\"text-highlight\">back<\/a><\/span> in the browser or use the upper menu to navigate to another page.", "messages", "en");
    Translator.add("error_page.contact", "If you want you can report to <a class=\"text-highlight\" href=\"mailto:apoiocliente@acascata.com\">apoiocliente@acascata.com<\/a>. Thank you.", "messages", "en");
    Translator.add("event_type_choices", "anniversary, wedding, meeting, etc.", "messages", "en");
    Translator.add("events", "Events", "messages", "en");
    Translator.add("exclusive", "exclusive", "messages", "en");
    Translator.add("expected_date", "expected date", "messages", "en");
    Translator.add("expected_hour", "expected hour", "messages", "en");
    Translator.add("faqs", "FAQS", "messages", "en");
    Translator.add("faqs.title", "Here to help you", "messages", "en");
    Translator.add("faqs.sub_title", "frequently asked questions", "messages", "en");
    Translator.add("fat", "Fat", "messages", "en");
    Translator.add("file_upload", "File upload", "messages", "en");
    Translator.add("filter.action", "Filter", "messages", "en");
    Translator.add("first_name", "First Name", "messages", "en");
    Translator.add("food_menus", "Menus", "messages", "en");
    Translator.add("for_any_question_please_contact_us", "For any enquiries, please contact us:", "messages", "en");
    Translator.add("form_email_example", "(ex: abc@example.com)", "messages", "en");
    Translator.add("full_name", "full name", "messages", "en");
    Translator.add("footer.copyrights", "Copyright @ %date% Grupo A Cascata", "messages", "en");
    Translator.add("form.address.address_line_1", "Address (Line 1)", "messages", "en");
    Translator.add("form.address.address_line_2", "Address (Line 2)", "messages", "en");
    Translator.add("form.address.postal_code", "Postal Code", "messages", "en");
    Translator.add("form.address.loality", "Locality", "messages", "en");
    Translator.add("form.address.city", "City", "messages", "en");
    Translator.add("head_office", "Head office", "messages", "en");
    Translator.add("help.dynamic.vars", "ex: {category} will be the name of category in the language", "messages", "en");
    Translator.add("home", "Home", "messages", "en");
    Translator.add("homepage_food_menu_1", "start", "messages", "en");
    Translator.add("homepage_food_menu_2", "grill", "messages", "en");
    Translator.add("homepage_food_menu_3", "burger", "messages", "en");
    Translator.add("homepage_food_menu_4", "classic", "messages", "en");
    Translator.add("homepage_food_menu_5", "snack", "messages", "en");
    Translator.add("homepage_food_menu_6", "green", "messages", "en");
    Translator.add("homepage_food_menu_7", "brunch", "messages", "en");
    Translator.add("homepage_food_menu_8", "sweet", "messages", "en");
    Translator.add("homepage.logo_title", "a good grill", "messages", "en");
    Translator.add("homepage.since_year", "Since 1992", "messages", "en");
    Translator.add("how_to_use", "How to use?", "messages", "en");
    Translator.add("human", "Human", "messages", "en");
    Translator.add("icon.flow.download", "Download your voucher", "messages", "en");
    Translator.add("icon.flow.store", "Present your voucher in our shop", "messages", "en");
    Translator.add("icon.flow.enjoy", "Enjoy your meal", "messages", "en");
    Translator.add("id", "ID", "messages", "en");
    Translator.add("in_which_cascata_restaurant", "in wich Cascata restaurant?", "messages", "en");
    Translator.add("insert_your_email", "insert your email", "messages", "en");
    Translator.add("innovation", "Innovation", "messages", "en");
    Translator.add("insert_your_email_and_join_the_newsletter", "Insert your email and receive our newsletter", "messages", "en");
    Translator.add("last_name", "Last Name", "messages", "en");
    Translator.add("language.en", "English", "messages", "en");
    Translator.add("language.pt", "Portuguese", "messages", "en");
    Translator.add("limit_2MB", "2 MB LIMIT", "messages", "en");
    Translator.add("locale.select.all", "All", "messages", "en");
    Translator.add("location", "Location", "messages", "en");
    Translator.add("login_and_earn_cascata_points", "Login and earn A Cascata points", "messages", "en");
    Translator.add("max_price_per_person", "Max price p\/ person", "messages", "en");
    Translator.add("modal.voucher.header", "Get our promotions now", "messages", "en");
    Translator.add("modal.voucher.subheader", "good & fast restaurant", "messages", "en");
    Translator.add("modal.voucher.description", "Insert your email to download our promotions", "messages", "en");
    Translator.add("money.input.placeholder", "Ex: 10,00", "messages", "en");
    Translator.add("message", "Message", "messages", "en");
    Translator.add("menu.legal", "The images of this menu are merely illustrative. We reserve the right to change the presentation of the same customer without prejudice to the customer. Including VAT at current rate.", "messages", "en");
    Translator.add("mobile", "Mobile", "messages", "en");
    Translator.add("month.1", "January", "messages", "en");
    Translator.add("month.2", "February", "messages", "en");
    Translator.add("month.3", "March", "messages", "en");
    Translator.add("month.4", "April", "messages", "en");
    Translator.add("month.5", "May", "messages", "en");
    Translator.add("month.6", "June", "messages", "en");
    Translator.add("month.7", "July", "messages", "en");
    Translator.add("month.8", "August", "messages", "en");
    Translator.add("month.9", "September", "messages", "en");
    Translator.add("month.10", "October", "messages", "en");
    Translator.add("month.11", "November", "messages", "en");
    Translator.add("month.12", "December", "messages", "en");
    Translator.add("monday_friday.min", "Mon - Fri", "messages", "en");
    Translator.add("mission_and_vision", "Mission & Vision", "messages", "en");
    Translator.add("mission_and_vision.text_1", "A Cascade in the coming years wants to become the largest national chain of francesinhas & fast food restaurant, in a constant search for perfection in all details with the goal of getting customers for life", "messages", "en");
    Translator.add("mission_and_vision.text_2", "A Cascade mission is to use the experience and know-how of over 20 years in catering to meet the requirements of each client and each employee, in order to achieve total satisfaction and obtain financial results to the company, consolidated a constant search for innovation and perfection in all details. ", "messages", "en");
    Translator.add("mission_and_vision.text_3", "\"Total perfection is impossible, but we pursue perfection in all details.\"", "messages", "en");
    Translator.add("more_details", "More Details", "messages", "en");
    Translator.add("name", "Name", "messages", "en");
    Translator.add("newsletter.registration.success.header", "Newsletter subscription", "messages", "en");
    Translator.add("newsletter.registration.success.body", "You are now a member of our newsletter. <br \/> Stay in touch!", "messages", "en");
    Translator.add("newsletter.registration.invalid.body", "Something went wrong. Please check if your email is valid.", "messages", "en");
    Translator.add("now_all_year", "now all the year", "messages", "en");
    Translator.add("no_results", "No results found for your search.", "messages", "en");
    Translator.add("number_of_people", "Number of people", "messages", "en");
    Translator.add("nutritional_values", "Nutritional values", "messages", "en");
    Translator.add("offers", "Take away", "messages", "en");
    Translator.add("our_offers", "Our offers", "messages", "en");
    Translator.add("offer_limited_until", "offer limited until %month% %day%, %year%", "messages", "en");
    Translator.add("other_promotions", "view other offers", "messages", "en");
    Translator.add("our_values", "Our Values", "messages", "en");
    Translator.add("overall", "Overall", "messages", "en");
    Translator.add("opening.hours", "Opening hours", "messages", "en");
    Translator.add("orders", "Orders", "messages", "en");
    Translator.add("take_away", "Take Away", "messages", "en");
    Translator.add("partnerships.header", "Partnerships", "messages", "en");
    Translator.add("permission_denied_edit", "You have no edit permissions.", "messages", "en");
    Translator.add("phone", "Phone", "messages", "en");
    Translator.add("positions_updated", "Positions updated!", "messages", "en");
    Translator.add("price", "price", "messages", "en");
    Translator.add("product_quality", "Product quality", "messages", "en");
    Translator.add("product_picture", "Product Picture", "messages", "en");
    Translator.add("promotions.header", "DO NOT RESIST TO TASTEFUL OFFERS", "messages", "en");
    Translator.add("promotions.sub_header", "good & fast restaurant", "messages", "en");
    Translator.add("protein", "Protein", "messages", "en");
    Translator.add("quality", "Quality", "messages", "en");
    Translator.add("questions", "Questions", "messages", "en");
    Translator.add("quickness", "Quickness", "messages", "en");
    Translator.add("rating", "Rating", "messages", "en");
    Translator.add("read_more", "Read more", "messages", "en");
    Translator.add("resistance", "Resistance", "messages", "en");
    Translator.add("restaurant", "A CASCATA", "messages", "en");
    Translator.add("salt", "Salt", "messages", "en");
    Translator.add("score", "Score", "messages", "en");
    Translator.add("search", "Search", "messages", "en");
    Translator.add("search_again", "Please type another query to search", "messages", "en");
    Translator.add("search_here", "place your search here", "messages", "en");
    Translator.add("search.address", "place your search here", "messages", "en");
    Translator.add("see_catalog", "See Catalog", "messages", "en");
    Translator.add("select_your_subject", "select your subject", "messages", "en");
    Translator.add("send", "Send", "messages", "en");
    Translator.add("show_more", "Show more", "messages", "en");
    Translator.add("store", "Store", "messages", "en");
    Translator.add("store.tel", "Pho.:", "messages", "en");
    Translator.add("store.mob", "Mob.:", "messages", "en");
    Translator.add("subject", "Subject", "messages", "en");
    Translator.add("summer_time", "Summer time", "messages", "en");
    Translator.add("to", "To", "messages", "en");
    Translator.add("treatment", "Treatment", "messages", "en");
    Translator.add("trustiness", "Trustiness", "messages", "en");
    Translator.add("type", "Type", "messages", "en");
    Translator.add("view_events", "view events", "messages", "en");
    Translator.add("view_food_menu", "View food menu", "messages", "en");
    Translator.add("votes", "Votes", "messages", "en");
    Translator.add("voucher.download.start", "Thanks for downloading our promotions. <br>Have a happy meal!", "messages", "en");
    Translator.add("whats_your_wish", "What's your wish?", "messages", "en");
    Translator.add("want_a_budget", "Want a budget?", "messages", "en");
    Translator.add("we_expect_your_contact", "We expect your contact", "messages", "en");
    Translator.add("we_expect_your_visit", "We expect your visit", "messages", "en");
    Translator.add("we_share_stories", "Our  stories", "messages", "en");
    Translator.add("week_offer", "Week offer", "messages", "en");
    Translator.add("where_are_we", "Where are we?", "messages", "en");
    Translator.add("which_cascata_restaurant", "which A Cascata restaurant?", "messages", "en");
    Translator.add("write_your_message", "write your message", "messages", "en");
    Translator.add("your_first_name", "your first name", "messages", "en");
    Translator.add("your_email", "your email", "messages", "en");
    Translator.add("your_last_name", "your last name", "messages", "en");
    Translator.add("your_name", "your name", "messages", "en");
    Translator.add("your_opinion_is_very_important", "Your opinion is very important", "messages", "en");
    Translator.add("your_phone_contact", "your phone contact", "messages", "en");
    Translator.add("call_costs_land", "(\u00b9 Local and national telephone rates apply)", "messages", "en");
    Translator.add("livro_reclamacoes", "Complaints book", "messages", "en");
})(Translator);
