(function (Translator) {
    // pt
    Translator.add("a_good", "a good", "messages", "pt");
    Translator.add("a_good_", "a good ...", "messages", "pt");
    Translator.add("about_us", "Sobre N\u00f3s", "messages", "pt");
    Translator.add("add", "Adicionar", "messages", "pt");
    Translator.add("address", "Morada", "messages", "pt");
    Translator.add("address.city", "Cidade", "messages", "pt");
    Translator.add("address.edit", "Editar morada", "messages", "pt");
    Translator.add("address.locality", "Localidade", "messages", "pt");
    Translator.add("address.postal_code", "C\u00f3digo Postal", "messages", "pt");
    Translator.add("all_food_menus_details_available", "Todos os detalhes das ementas dispon\u00edveis", "messages", "pt");
    Translator.add("all_info_at_the_distance_of_one_click", "toda a informa\u00e7\u00e3o \u00e0 dist\u00e2ncia de um click", "messages", "pt");
    Translator.add("allergen_1", "gluten", "messages", "pt");
    Translator.add("allergen_2", "crust\u00e1ceos", "messages", "pt");
    Translator.add("allergen_3", "ovos", "messages", "pt");
    Translator.add("allergen_4", "peixe", "messages", "pt");
    Translator.add("allergen_5", "amendo\u00edns", "messages", "pt");
    Translator.add("allergen_6", "soja", "messages", "pt");
    Translator.add("allergen_7", "leite", "messages", "pt");
    Translator.add("allergen_8", "frutos de casca rija", "messages", "pt");
    Translator.add("allergen_9", "aipo", "messages", "pt");
    Translator.add("allergen_10", "mostarda", "messages", "pt");
    Translator.add("allergen_11", "s\u00e9samo", "messages", "pt");
    Translator.add("allergen_12", "di\u00f3xido de enxofre\/sulfitos > 10mg\/Kg", "messages", "pt");
    Translator.add("allergen_13", "tremo\u00e7o", "messages", "pt");
    Translator.add("allergen_14", "moluscos", "messages", "pt");
    Translator.add("allergens", "Alerg\u00e9nios", "messages", "pt");
    Translator.add("ambience", "Ambiente", "messages", "pt");
    Translator.add("any_info_you_want_to_add", "alguma informa\u00e7\u00e3o que queira acrescentar?", "messages", "pt");
    Translator.add("any_observation", "alguma observa\u00e7\u00e3o?", "messages", "pt");
    Translator.add("be_the_first_to_know_our_news", "Seja o primeiro a saber as nossas novidades", "messages", "pt");
    Translator.add("blog", "Blog", "messages", "pt");
    Translator.add("blog.header", "Partilhamos hist\u00f3rias", "messages", "pt");
    Translator.add("blog.sub_header", "good & fast restaurant", "messages", "pt");
    Translator.add("blog.menu.categories", "Categorias", "messages", "pt");
    Translator.add("blog.menu.latest", "Publica\u00e7\u00f5es recentes", "messages", "pt");
    Translator.add("browse", "Procurar", "messages", "pt");
    Translator.add("button.back", "Voltar", "messages", "pt");
    Translator.add("cancel", "Cancelar", "messages", "pt");
    Translator.add("carbohydrates", "Hidratos de Carbono", "messages", "pt");
    Translator.add("category_picture", "Category Picture", "messages", "pt");
    Translator.add("change", "Alterar", "messages", "pt");
    Translator.add("close", "Fechar", "messages", "pt");
    Translator.add("cookies", "Este site usa cookies para garantir a sua melhor experi\u00eancia no nosso site. <a aria-label = \"saiba mais sobre cookies\" role=\"button\"         tabindex=\"0\" class=\"cc-link\" href=\"https:\/\/cookiesandyou.com\" rel=\"noopener noreferrer nofollow\" target=\"_blank\">Saber Mais<\/a>", "messages", "pt");
    Translator.add("contact_us.subject.1", "Apoio ao cliente", "messages", "pt");
    Translator.add("contact_us.subject.2", "Franchising", "messages", "pt");
    Translator.add("contact_us.subject.3", "Recrutamento", "messages", "pt");
    Translator.add("contact_us.subject.4", "Sugest\u00f5es", "messages", "pt");
    Translator.add("contact_us.subject.5", "Outros", "messages", "pt");
    Translator.add("contacts", "Contactos", "messages", "pt");
    Translator.add("country_default", "PT", "messages", "pt");
    Translator.add("customer_service", "Servi\u00e7o de atendimento", "messages", "pt");
    Translator.add("daily.intake.percentage", "% da dose di\u00e1ria recomendada para um adulto", "messages", "pt");
    Translator.add("dashboard", "Painel de Controlo", "messages", "pt");
    Translator.add("date_string", "%day% de %month% de %year%", "messages", "pt");
    Translator.add("day", "Dia", "messages", "pt");
    Translator.add("delete", "Apagar", "messages", "pt");
    Translator.add("description", "Descri\u00e7\u00e3o", "messages", "pt");
    Translator.add("do_your_search", "fa\u00e7a a sua pesquisa", "messages", "pt");
    Translator.add("download", "Download", "messages", "pt");
    Translator.add("DIALOGS_OK", "OK", "messages", "pt");
    Translator.add("email", "Email", "messages", "pt");
    Translator.add("energy", "Energia", "messages", "pt");
    Translator.add("english_day", "[1,31 ]%count%", "messages", "pt");
    Translator.add("error", "Erro", "messages", "pt");
    Translator.add("error.ajax", "Ups, ocorreu um erro...", "messages", "pt");
    Translator.add("error_page.title", "Ups, ocorreu um erro...", "messages", "pt");
    Translator.add("erro_page.sub_title", "Tente <span><a href=\"Javascript:window.history.back();\" class=\"text-highlight\">retroceder<\/a><\/span> no browser ou use o menu superior para navegar para outra p\u00e1gina.", "messages", "pt");
    Translator.add("error_page.contact", "Se desejar pode reportar o erro para <a class=\"text-highlight\" href=\"mailto:apoiocliente@acascata.com\">apoiocliente@acascata.com<\/a>. Muito Obrigado.", "messages", "pt");
    Translator.add("event_type_choices", "anivers\u00e1rio, casamento, encontro, etc.", "messages", "pt");
    Translator.add("events", "Eventos", "messages", "pt");
    Translator.add("exclusive", "exclusive", "messages", "pt");
    Translator.add("expected_date", "data prevista", "messages", "pt");
    Translator.add("expected_hour", "hora prevista", "messages", "pt");
    Translator.add("faqs", "FAQs", "messages", "pt");
    Translator.add("faqs.title", "quest\u00f5es frequentes", "messages", "pt");
    Translator.add("faqs.sub_title", "Saiba mais sobre A Cascata", "messages", "pt");
    Translator.add("fat", "Gordura", "messages", "pt");
    Translator.add("file_upload", "Upload ficheiro", "messages", "pt");
    Translator.add("filter.action", "Filtrar", "messages", "pt");
    Translator.add("first_name", "Primeiro Nome", "messages", "pt");
    Translator.add("food_menus", "Ementas", "messages", "pt");
    Translator.add("for_any_question_please_contact_us", "Para qualquer quest\u00e3o, por favor contacte-nos:", "messages", "pt");
    Translator.add("form_email_example", "(ex: abc@example.com)", "messages", "pt");
    Translator.add("full_name", "Nome completo", "messages", "pt");
    Translator.add("footer.copyrights", "Copyright @ %date% Grupo A Cascata", "messages", "pt");
    Translator.add("form.address.address_line_1", "Endere\u00e7o (Linha 1)", "messages", "pt");
    Translator.add("form.address.address_line_2", "Endere\u00e7o (Linha 2)", "messages", "pt");
    Translator.add("form.address.postal_code", "C\u00f3digo Postal", "messages", "pt");
    Translator.add("form.address.loality", "Localidade", "messages", "pt");
    Translator.add("form.address.city", "Cidade", "messages", "pt");
    Translator.add("head_office", "Sede", "messages", "pt");
    Translator.add("help.dynamic.vars", "ex: {category} will be the name of category in the language", "messages", "pt");
    Translator.add("home", "Home", "messages", "pt");
    Translator.add("homepage_food_menu_1", "start", "messages", "pt");
    Translator.add("homepage_food_menu_2", "grill", "messages", "pt");
    Translator.add("homepage_food_menu_3", "burger", "messages", "pt");
    Translator.add("homepage_food_menu_4", "classic", "messages", "pt");
    Translator.add("homepage_food_menu_5", "snack", "messages", "pt");
    Translator.add("homepage_food_menu_6", "green", "messages", "pt");
    Translator.add("homepage_food_menu_7", "brunch", "messages", "pt");
    Translator.add("homepage_food_menu_8", "sweet", "messages", "pt");
    Translator.add("homepage.logo_title", "a good grill", "messages", "pt");
    Translator.add("homepage.since_year", "Desde 1992", "messages", "pt");
    Translator.add("how_to_use", "Como usar?", "messages", "pt");
    Translator.add("human", "Humano", "messages", "pt");
    Translator.add("icon.flow.download", "Fa\u00e7a o download do ficheiro e imprima", "messages", "pt");
    Translator.add("icon.flow.store", "Dirija-se a uma das nossas superf\u00edcies comerciais e apresente o seu vale de desconto", "messages", "pt");
    Translator.add("icon.flow.enjoy", "Aproveite a sua refei\u00e7\u00e3o", "messages", "pt");
    Translator.add("id", "ID", "messages", "pt");
    Translator.add("in_which_cascata_restaurant", "em qual restaurante Cascata?", "messages", "pt");
    Translator.add("innovation", "Inova\u00e7\u00e3o", "messages", "pt");
    Translator.add("insert_your_email", "introduza o seu email", "messages", "pt");
    Translator.add("insert_your_email_and_join_the_newsletter", "Introduza o seu email e receba a nossa newsletter", "messages", "pt");
    Translator.add("last_name", "\u00daltimo Nome", "messages", "pt");
    Translator.add("language.en", "Ingl\u00eas", "messages", "pt");
    Translator.add("language.pt", "Portugu\u00eas", "messages", "pt");
    Translator.add("limit_2MB", "Limite 2 MB", "messages", "pt");
    Translator.add("locale.select.all", "Todos", "messages", "pt");
    Translator.add("location", "Localiza\u00e7\u00e3o", "messages", "pt");
    Translator.add("login_and_earn_cascata_points", "Inicie sess\u00e3o e ganhe pontos A Cascata", "messages", "pt");
    Translator.add("max_price_per_person", "Pre\u00e7o m\u00e1x p\/ pessoa", "messages", "pt");
    Translator.add("modal.voucher.header", "Fa\u00e7a o download das nossas promo\u00e7\u00f5es", "messages", "pt");
    Translator.add("modal.voucher.subheader", "good & fast restaurant", "messages", "pt");
    Translator.add("modal.voucher.description", "Insira o seu email para fazer o download", "messages", "pt");
    Translator.add("money.input.placeholder", "Ex: 10,00", "messages", "pt");
    Translator.add("message", "Mensagem", "messages", "pt");
    Translator.add("menu.legal", "As imagens desta ementa s\u00e3o meramente ilustrativas. Reservamos o direito de alterar a apresenta\u00e7\u00e3o das mesmas sem prejuizo do cliente. IVA incluido \u00e0 taxa em vigor.", "messages", "pt");
    Translator.add("mobile", "Telem\u00f3vel", "messages", "pt");
    Translator.add("month.1", "Janeiro", "messages", "pt");
    Translator.add("month.2", "Fevereiro", "messages", "pt");
    Translator.add("month.3", "Mar\u00e7o", "messages", "pt");
    Translator.add("month.4", "Abril", "messages", "pt");
    Translator.add("month.5", "Maio", "messages", "pt");
    Translator.add("month.6", "Junho", "messages", "pt");
    Translator.add("month.7", "Julho", "messages", "pt");
    Translator.add("month.8", "Agosto", "messages", "pt");
    Translator.add("month.9", "Setembro", "messages", "pt");
    Translator.add("month.10", "Outubro", "messages", "pt");
    Translator.add("month.11", "Novembro", "messages", "pt");
    Translator.add("month.12", "Dezembro", "messages", "pt");
    Translator.add("monday_friday.min", "Seg - Sex", "messages", "pt");
    Translator.add("mission_and_vision", "Miss\u00e3o & Vis\u00e3o", "messages", "pt");
    Translator.add("mission_and_vision.text_1", "A Cascata quer tornar-se a maior cadeia nacional de francesinhas e comida r\u00e1pida nos pr\u00f3ximos anos, numa procura constante da perfei\u00e7\u00e3o em pequenos detalhes com o objectivo de conseguir clientes para toda a vida", "messages", "pt");
    Translator.add("mission_and_vision.text_2", "A Cascata tem como miss\u00e3o utilizar a sua experi\u00eancia e know-how de mais de 20 anos na restaura\u00e7\u00e3o para atingir os crit\u00e9rios de exig\u00eancia de cada cliente e de cada colaborador, de forma a atingir a satisfa\u00e7\u00e3o total e obter resultados financeiros para a empresa, consolidados numa busca permanente da inova\u00e7\u00e3o e perfei\u00e7\u00e3o nos detalhes.", "messages", "pt");
    Translator.add("mission_and_vision.text_3", "\"A perfei\u00e7\u00e3o total \u00e9 imposs\u00edvel, mas perseguimos a perfei\u00e7\u00e3o nos detalhes.\"", "messages", "pt");
    Translator.add("more_details", "Mais Detalhes", "messages", "pt");
    Translator.add("name", "Nome", "messages", "pt");
    Translator.add("newsletter.registration.success.header", "Subscri\u00e7\u00e3o na newsletter", "messages", "pt");
    Translator.add("newsletter.registration.success.body", "A sua subscri\u00e7\u00e3o foi um sucesso. <br \/> Em breve receber\u00e1 a nossa Newsletter", "messages", "pt");
    Translator.add("newsletter.registration.invalid.body", "Algo correu mal. Verifique se o seu e-mail \u00e9 v\u00e1lido.", "messages", "pt");
    Translator.add("now_all_year", "agora todo o ano", "messages", "pt");
    Translator.add("no_results", "Sem resultados para a sua pesquisa.", "messages", "pt");
    Translator.add("number_of_people", "N\u00famero de pessoas", "messages", "pt");
    Translator.add("nutritional_values", "Valores nutricionais", "messages", "pt");
    Translator.add("orders", "Orders", "messages", "pt");
    Translator.add("take_away", "Take Away", "messages", "pt");
    Translator.add("offers", "Ofertas", "messages", "pt");
    Translator.add("our_offers", "As nossas ofertas", "messages", "pt");
    Translator.add("offer_limited_until", "oferta limitada at\u00e9 dia %day% de %month% de %year%", "messages", "pt");
    Translator.add("other_promotions", "VER OUTRAS CAMPANHAS", "messages", "pt");
    Translator.add("our_values", "Quais os nossos valores", "messages", "pt");
    Translator.add("overall", "Nota Final", "messages", "pt");
    Translator.add("opening.hours", "Hor\u00e1rio", "messages", "pt");
    Translator.add("partnerships.header", "Parcerias", "messages", "pt");
    Translator.add("permission_denied_edit", "N\u00e3o tem permiss\u00e3o para altera\u00e7\u00f5es.", "messages", "pt");
    Translator.add("phone", "Telefone", "messages", "pt");
    Translator.add("positions_updated", "Posi\u00e7\u00f5es actualizadas!", "messages", "pt");
    Translator.add("price", "pre\u00e7o", "messages", "pt");
    Translator.add("product_quality", "Qualidade dos produtos", "messages", "pt");
    Translator.add("product_picture", "Product Picture", "messages", "pt");
    Translator.add("promotions.header", "N\u00c3O RESISTA A OFERTAS DE BOM GOSTO", "messages", "pt");
    Translator.add("promotions.sub_header", "good & fast restaurant", "messages", "pt");
    Translator.add("protein", "Prote\u00edna", "messages", "pt");
    Translator.add("quality", "Qualidade", "messages", "pt");
    Translator.add("questions", "Quest\u00f5es", "messages", "pt");
    Translator.add("quickness", "Rapidez", "messages", "pt");
    Translator.add("rating", "Avalia\u00e7\u00e3o", "messages", "pt");
    Translator.add("read_more", "Ler mais...", "messages", "pt");
    Translator.add("resistance", "Resist\u00eancia", "messages", "pt");
    Translator.add("restaurant", "A CASCATA", "messages", "pt");
    Translator.add("salt", "Sal", "messages", "pt");
    Translator.add("score", "Nota", "messages", "pt");
    Translator.add("search", "Procurar", "messages", "pt");
    Translator.add("search_again", "Por favor procure por outras palavras-chave", "messages", "pt");
    Translator.add("search.address", "coloque a sua pesquisa aqui", "messages", "pt");
    Translator.add("search_here", "coloque a sua pesquisa aqui", "messages", "pt");
    Translator.add("see_catalog", "Ver Cat\u00e1logo", "messages", "pt");
    Translator.add("select_your_subject", "seleccione o seu assunto", "messages", "pt");
    Translator.add("send", "Enviar", "messages", "pt");
    Translator.add("show_more", "Ver mais", "messages", "pt");
    Translator.add("store", "Loja", "messages", "pt");
    Translator.add("store.tel", "Tel.:", "messages", "pt");
    Translator.add("store.mob", "Tlm.:", "messages", "pt");
    Translator.add("subject", "Assunto", "messages", "pt");
    Translator.add("summer_time", "A Cascata Praia", "messages", "pt");
    Translator.add("to", "at\u00e9", "messages", "pt");
    Translator.add("treatment", "Tratamento", "messages", "pt");
    Translator.add("trustiness", "Confian\u00e7a", "messages", "pt");
    Translator.add("type", "Tipo", "messages", "pt");
    Translator.add("view_food_menu", "ver menu", "messages", "pt");
    Translator.add("view_events", "ver eventos", "messages", "pt");
    Translator.add("votes", "Votos", "messages", "pt");
    Translator.add("voucher.download.start", "Obrigado por fazer download das nossas promo\u00e7\u00f5es. <br>Bom apetite!", "messages", "pt");
    Translator.add("whats_your_wish", "Qual \u00e9 o seu desejo?", "messages", "pt");
    Translator.add("want_a_budget", "Pretende um or\u00e7amento?", "messages", "pt");
    Translator.add("we_expect_your_contact", "Esperamos o seu contacto", "messages", "pt");
    Translator.add("we_expect_your_visit", "Esperamos a sua visita", "messages", "pt");
    Translator.add("we_share_stories", "Partilhamos hist\u00f3rias", "messages", "pt");
    Translator.add("week_offer", "Oferta da Semana", "messages", "pt");
    Translator.add("where_are_we", "Onde Estamos?", "messages", "pt");
    Translator.add("which_cascata_restaurant", "em qual restaurante Cascata?", "messages", "pt");
    Translator.add("write_your_message", "escreva a sua mensagem", "messages", "pt");
    Translator.add("your_first_name", "o seu primeiro nome", "messages", "pt");
    Translator.add("your_email", "o seu email", "messages", "pt");
    Translator.add("your_last_name", "o seu \u00faltimo nome", "messages", "pt");
    Translator.add("your_name", "o seu nome", "messages", "pt");
    Translator.add("your_opinion_is_very_important", "A sua opini\u00e3o \u00e9 muito importante", "messages", "pt");
    Translator.add("your_phone_contact", "o seu contacto telef\u00f3nico", "messages", "pt");
    Translator.add("call_costs_land", "(\u00b9 Aplicam-se as tarifas telef\u00f3nicas locais e nacionais)", "messages", "pt");
    Translator.add("livro_reclamacoes", "Livro de Reclama\u00e7\u00f5es", "messages", "pt");
})(Translator);
